import { Pipe, PipeTransform } from '@angular/core';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { Storage } from '@ionic/storage-angular';
import { parseISO} from 'date-fns';

import { zonedTimeToUtc } from 'date-fns-tz'
import { utcToZonedTime } from 'date-fns-tz'
import { es, enCA } from 'date-fns/locale';

@Pipe({
  name: 'relativeTime'
})

export class RelativeTimePipe implements PipeTransform {
  language:any;
  timeZone:any;
  constructor(public storage: Storage){
   this.storage.get('language').then((val0) => {
    this.language=  val0;
  });
  this.storage.get('timeZone').then((val) => {
    if(val){
      this.timeZone=  val
    }else{
      this.timeZone=  Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.storage.set('timeZone',this.timeZone);
    }
    
  
  });

  }


  transform(value: any) {
console.log(this.timeZone);
console.log(this.language);
    var date_utc = zonedTimeToUtc(value, 'Asia/Shanghai');
    var user_d = utcToZonedTime( date_utc, this.timeZone)
    if(this.language == "es") {
      console.log('espanol');
      return formatDistanceToNow(user_d,{locale: es});
    }else if(this.language=="en"){
      console.log('espanol');
      return formatDistanceToNow(user_d,{locale: enCA});
    }else{
      console.log('espanol');
      return formatDistanceToNow(user_d,{locale: enCA});
    }
  
  }

}
