import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';

import { ExternalService } from '../../providers/external.service';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-lang-popover',
  templateUrl: './lang-popover.component.html',
  styleUrls: ['./lang-popover.component.scss'],
})
export class LangPopoverComponent implements OnInit {

	language:any;
  constructor(private storage: Storage, private event: ExternalService, public popoverController: PopoverController) { }

  ngOnInit() {

	this.storage.get('language').then((val) => {
        this.language= val;
	})

  }





  toEnglish(){
		//   this.events.publish('language: change', null);
			   this.storage.set('language', 'en').then(() => {
			 this.event.updateLanguage('en');
			 //this.menuCtrl.enable(false);
			 //this.navCtrl.navigateRoot('/splash');
       this.dismissPop();
       
	   
	   });
	      
			 
			 }
			 
			 toChinese(){
		   //  this.events.publish('language: change', 'cn');
			   this.storage.set('language', 'cn').then(() => {
			 this.event.updateLanguage('cn');
       this.dismissPop();
			 });
		   }
			 
			 
			 toSpanish(){
		   //  this.events.publish('language: change', 'sp');
			   this.storage.set('language', 'es').then(() => {
			 this.event.updateLanguage('es');
       this.dismissPop();
			 });
		   }

       async dismissPop(){
        await this.popoverController.dismiss();
       }

}


