
import { Component, OnInit, Input, ViewChild, ElementRef} from '@angular/core';
import { NavParams, ModalController,IonContent } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { ExternalService } from 'src/app/providers/external.service';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-resultados',
  templateUrl: './resultados.component.html',
  styleUrls: ['./resultados.component.scss'],
})
export class ResultadosComponent implements OnInit {
  @ViewChild(IonContent, { static: false }) content: IonContent;
  @ViewChild('tt') tt : ElementRef;
  @ViewChild('tt1') tt1 : ElementRef;
  @ViewChild('tt2') tt2 : ElementRef;
  @ViewChild('tt3') tt3 : ElementRef;
  @ViewChild('tt4') tt4 : ElementRef;
  @ViewChild('tt5') tt5 : ElementRef;
  @ViewChild('tt6') tt6 : ElementRef;
  @ViewChild('tt7') tt7 : ElementRef;
  @ViewChild('tt8') tt8 : ElementRef;
  @ViewChild('tt9') tt9 : ElementRef;
  @ViewChild('tt10') tt10 : ElementRef;
  @ViewChild('tt11') tt11 : ElementRef;

  public tipo_cambio = this.navParams.get('tipo_cambio');
  public peso = this.navParams.get('peso');
  public volumen = this.navParams.get('volumen');
  public total = this.navParams.get('total');
  public total_gastos_usd:any;
  public total_gastos_d:any;
  public total_f: any;
products:any;
ready:number = 0;



@ViewChild('chartCanvas') chartCanvas : ElementRef;
data : any = [];
canvasChart : Chart;;

  constructor( private storage:Storage, private navParams: NavParams, private modalCtrl: ModalController, public external: ExternalService) { }

  async ngOnInit() {
    
this.total_f = this.total;
    await this.storage.create();




    

      this.storage.get('order2quote').then((val) => {
        this.products = val;
        this.ready=1;

        setTimeout(() => {
         
          this.changeChart()
  }, 3000);
      });

      


  
  }


  closeModal(){
    this.modalCtrl.dismiss();
  }

  async grafico() {
    let goods = this.products;

    let labels = [];
    let renta = [];




    Object.keys(goods).forEach((key, index, array) => {
      if (index > 2) {
        return true;
      }
      labels.push(goods[key].name);
      renta.push((goods[key].rent/(goods[key].costo_unidad * goods[key].moq ))+1);
    });
    
    this.data = {
      labels: labels,
      datasets: [{
        label: 'Factor Retorno',
        data: renta,
        backgroundColor: '#3e6e9e',
        borderColor: '#f9f9f9',
        borderWidth: 2
      }]
    };

  
   
   
  }
  changeChart() {
    this.grafico();
    const type ='bar';
    if ( this.canvasChart ) {
      this.canvasChart.destroy();
    }
    this.canvasChart = new Chart(this.chartCanvas.nativeElement, {
      type: type,
      data: this.data,
      options: {
        indexAxis: 'x'
      }
    });
  }
  addRandom( points: any ) : number {
    return Number(points) - Number( Math.floor((Math.random() * 100) + 1) );
  }
   

gastoextra(ev){
  
var total_inv = this.total + (ev/this.tipo_cambio);
this.total_f = this.total + (ev/this.tipo_cambio);
   var tipo_cambio = this.tipo_cambio;
    this.products.forEach(function(p) {

      p.costo_unidad= (p.pe*total_inv*tipo_cambio)/p.moq;
      p.pv = p.pv;
      p.pav = p.pav;
      p.rent = (p.pv-p.costo_unidad)*p.moq;
 });

this.storage.set('order2quote',this.products);
setTimeout(() => {
         
  this.changeChart();
}, 1500);

}


precioventa(i,ev){

  if(ev.length >0){

  }else{ 
  setTimeout(() => {
       
    this.content.scrollToPoint(0,  document.getElementById("tt"+i).offsetTop-180,500)

}, 300);
}
  this.total = this.total + (ev/this.tipo_cambio);
  var total_inv = this.total;
     var tipo_cambio = this.tipo_cambio;
      this.products[i].pv = ev;
      this.products[i].pav =  this.products[i].moq * ev;
      this.products[i].rent =  (ev - this.products[i].costo_unidad )* this.products[i].moq;
      
  this.storage.set('order2quote',this.products);
  setTimeout(() => {
         
    this.changeChart()
}, 1500);

  
  }

  ionViewWillLeave(){
    this.total = null;
    this.products = [];
    this.modalCtrl.dismiss({
      'dismissed': true
    });
    console.log('dismiss');
  }


}
