import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import { Storage } from '@ionic/storage';
import {enUS, eo, es, zhCN} from 'date-fns/esm/locale';
import {parseISO, formatDistanceToNow, getYear} from 'date-fns';
import { format } from 'date-fns';

import { zonedTimeToUtc } from 'date-fns-tz'
import { utcToZonedTime } from 'date-fns-tz'

@Injectable({
  providedIn: 'root'
})
export class ExternalService {
  private x: boolean = false;
  private languageSubject$ = new BehaviorSubject<boolean>(this.x);
  private favoSubject$ = new BehaviorSubject<boolean>(this.x);
  private cbmSubject$ = new BehaviorSubject<boolean>(this.x);
  languageChanged$ = this.languageSubject$.asObservable();
  favoChanged$ = this.favoSubject$.asObservable();
  private userSubject$ = new BehaviorSubject<boolean>(this.x);
  private cartSubject$ = new BehaviorSubject<boolean>(this.x);
  userChanged$ = this.userSubject$.asObservable();
  cartChanged$ = this.cartSubject$.asObservable();
  
  year:any;
  public base_url = "https://pandala.com.cn/restpgt/";
	dateLocales = { es: es, en: enUS, cn: zhCN }
  constructor(private storage: Storage) {
   }

  updateLanguage(idioma) {
    this.languageSubject$.next(this.x);

    this.storage.set('language', idioma);
  }

  updateUser() {
    this.userSubject$.next(this.x);
  }

  updateFavo() {
    this.favoSubject$.next(this.x);
  }



  updateCart(a) {
    this.cartSubject$.next(this.x);
    this.storage.set('exw_list', a);
  }

  updateCBMcalculator(){
    this.cbmSubject$.next(this.x);
  }

getCurrentYeart(){
 let year =  getYear(new Date());
  return year;
}

formatMoney(money){
  const formatter = Intl.NumberFormat();
  return formatter.format(money);
}

replaceHash(a){

  const str = a.replace(/\//g, "%2F");
return str;
}

cleanClp(monto) {
  return monto.toString().replace(/\D/g, '');
}

format0Decimal(number){
  const formatter =  parseFloat(number).toFixed(0);
  return formatter;
}

formatChile(monto){
  var separator = ".";
  var symbol = ""
 monto =  this.formatInt(monto);
 let cleanValue = monto.toString().replace(/\D/g, '');
 let valueConverted = cleanValue ?cleanValue.split("").reverse() : "";
 if(!cleanValue)return "";
 const length = valueConverted.length;
 const divs = length / 3;
 const sobr = length % 3;
 let finalValue;
 let array = [];
 valueConverted.reduce((previus,current,index)=>{
     if(index % 3 == 0){
         array.push(previus.split("").reverse().join(""))
         return current
     }
     return previus+current;
 })
 if(sobr){
     let valSobr = valueConverted.reverse().slice(0,sobr)
     let point = length < 3 ? '' : separator;
     finalValue = valSobr.join('')+ point;
 }else{
     array.push(valueConverted.reverse().slice(0,3).join(''))
 }
 return `${symbol}${finalValue?finalValue:''}${array.reverse().join(separator)}`
}

formatMoneyCLP(monto) {
     var separator = ".";
     var symbol = "$"
    monto =  this.formatInt(monto);
    let cleanValue = monto.toString().replace(/\D/g, '');
    let valueConverted = cleanValue ?cleanValue.split("").reverse() : "";
    if(!cleanValue)return "";
    const length = valueConverted.length;
    const divs = length / 3;
    const sobr = length % 3;
    let finalValue;
    let array = [];
    valueConverted.reduce((previus,current,index)=>{
        if(index % 3 == 0){
            array.push(previus.split("").reverse().join(""))
            return current
        }
        return previus+current;
    })
    if(sobr){
        let valSobr = valueConverted.reverse().slice(0,sobr)
        let point = length < 3 ? '' : separator;
        finalValue = valSobr.join('')+ point;
    }else{
        array.push(valueConverted.reverse().slice(0,3).join(''))
    }
    return `${symbol}${finalValue?finalValue:''}${array.reverse().join(separator)}`
}

formatMoneyCLPmoq(monto,moq) {
  var separator = ".";
  var symbol = "$"
 monto =  this.format0Decimal(monto);
 monto = monto * moq;
 let cleanValue = monto.toString().replace(/\D/g, '');
 let valueConverted = cleanValue ?cleanValue.split("").reverse() : "";
 if(!cleanValue)return "";
 const length = valueConverted.length;
 const divs = length / 3;
 const sobr = length % 3;
 let finalValue;
 let array = [];
 valueConverted.reduce((previus,current,index)=>{
     if(index % 3 == 0){
         array.push(previus.split("").reverse().join(""))
         return current
     }
     return previus+current;
 })
 if(sobr){
     let valSobr = valueConverted.reverse().slice(0,sobr)
     let point = length < 3 ? '' : separator;
     finalValue = valSobr.join('')+ point;
 }else{
     array.push(valueConverted.reverse().slice(0,3).join(''))
 }
 return `${symbol}${finalValue?finalValue:''}${array.reverse().join(separator)}`
}

formatMoneyCLPmoqRent(monto,moq,costo_unidad) {
  var separator = ".";
  var symbol = "$"
 monto =  this.format0Decimal(monto);
 costo_unidad =  this.format0Decimal(costo_unidad);
 monto = (monto - costo_unidad)* moq;
 if(monto>0){monto}
 let cleanValue = monto.toString().replace(/\D/g, '');
 let valueConverted = cleanValue ?cleanValue.split("").reverse() : "";
 if(!cleanValue)return "";
 const length = valueConverted.length;
 const divs = length / 3;
 const sobr = length % 3;
 let finalValue;
 let array = [];
 valueConverted.reduce((previus,current,index)=>{
     if(index % 3 == 0){
         array.push(previus.split("").reverse().join(""))
         return current
     }
     return previus+current;
 })
 if(sobr){
     let valSobr = valueConverted.reverse().slice(0,sobr)
     let point = length < 3 ? '' : separator;
     finalValue = valSobr.join('')+ point;
 }else{
     array.push(valueConverted.reverse().slice(0,3).join(''))
 }
 return `${symbol}${finalValue?finalValue:''}${array.reverse().join(separator)}`
}



  dateEn(date){
    var date_utc = zonedTimeToUtc(date, 'Asia/Shanghai');
    var user_d = utcToZonedTime( date_utc,Intl.DateTimeFormat().resolvedOptions().timeZone )
    const formatter = format(user_d, 'PPP', {locale: enUS	});
    return formatter;
  }

  dateCn(date){
    var date_utc = zonedTimeToUtc(date, 'Asia/Shanghai');
    var user_d = utcToZonedTime( date_utc,Intl.DateTimeFormat().resolvedOptions().timeZone )
    const formatter = format(user_d, 'PPP', {locale: zhCN	});
    return formatter;
  }



  dateEs(date){
    var date_utc = zonedTimeToUtc(date, 'Asia/Shanghai');
    var user_d = utcToZonedTime( date_utc,Intl.DateTimeFormat().resolvedOptions().timeZone )
    const formatter = format(user_d, 'PPP', {locale: es	});
    return formatter;
  }

  dateEsBirth(date){
    var user_d = utcToZonedTime( date,Intl.DateTimeFormat().resolvedOptions().timeZone )
    const formatter = format(user_d, 'PPP', {locale: es	});
    return formatter;
  }


  dateEnBirth(date){
    var user_d = utcToZonedTime( date,Intl.DateTimeFormat().resolvedOptions().timeZone )
    const formatter = format(user_d, 'PPP', {locale: enUS	});
    return formatter;
  }


  dateEn2(date){
    var date_utc = zonedTimeToUtc(date, 'Asia/Shanghai');
    var user_d = utcToZonedTime( date_utc,Intl.DateTimeFormat().resolvedOptions().timeZone )
    const formatter = format(user_d, 'PPP', {locale: enUS	});
    return formatter;
  }

  dateCn2(date){
    var date_utc = zonedTimeToUtc(date, 'Asia/Shanghai');
    var user_d = utcToZonedTime( date_utc,Intl.DateTimeFormat().resolvedOptions().timeZone )
    const formatter = format(user_d, 'PPP', {locale: zhCN	});
    return formatter;
  }

  dateEs2(date){
    var date_utc = zonedTimeToUtc(date, 'Asia/Shanghai');
    var user_d = utcToZonedTime( date_utc,Intl.DateTimeFormat().resolvedOptions().timeZone )
    const formatter = format(user_d, 'PPP', {locale: es	});
    return formatter;
  }

  relativeTime(value: any) {
    var date_utc = zonedTimeToUtc(value, 'Asia/Shanghai');
    var user_d = utcToZonedTime( date_utc,Intl.DateTimeFormat().resolvedOptions().timeZone )
    return formatDistanceToNow((user_d));
  }

  relativeTimeEs(value: any) {
    var date_utc = zonedTimeToUtc(value, 'Asia/Shanghai');
    var user_d = utcToZonedTime( date_utc,Intl.DateTimeFormat().resolvedOptions().timeZone )
    return formatDistanceToNow((user_d),{locale: es});
  }


  //Currrencies


formatNumber(number){
  const formatter = Intl.NumberFormat();
  return formatter.format(number);
}


formatRound(number){
  const formatter = Math.ceil(number)
  return formatter;
}

format2Decimal(number){
  const formatter =  parseFloat(number).toFixed(2);
  return formatter;
}


formatDecimal(number){
 var  int_part = Math.trunc(number); 
  const formatter = Math.round(Number((number-int_part).toFixed(2))*100);
  if(formatter ==0){
    return ('00');
  }else{
     if(formatter.toString().length == 2){
      return (formatter);
     }else{
      return ('0'+formatter);
     }
   
   
  }

}

formatInt(number){
  const formatter = Math.trunc(number);
  if(formatter == 0){
    return (' 0 ');
  }else {
    return (formatter);
  }

}


}