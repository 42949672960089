import { Component, OnInit, HostListener } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ExternalService } from '../../providers/external.service';
import {Subscription} from 'rxjs';
import { Router} from '@angular/router';
import { TermsComponent } from '../terms/terms.component';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  languageChangedSubscription: Subscription;
  userChangedSubscription: Subscription;
  fullname:any;
  language:any;
  screen:any;
  user:any;
  screenHeight: number;
  screenWidth: number;
  version: string;
  constructor(private storage: Storage, public event: ExternalService, public route: Router, private modalCtrl: ModalController) {
    this.storage.get('language').then((val) => {
      if(val)
      this.language= val;
     })
  
  
      this.storage.get('user').then((obj) => {
     if(obj){
  
      this.fullname = obj.fullname.replace(/ .*/,'');
  
  
     }
  
  
        });

    this.getScreenSize();
   }

  ngOnInit() {

    this.languageChangedSubscription = this.event.languageChanged$.subscribe(()=>{
      this.storage.get('language').then((val) => {
        this.language= val;
       })
	})
	




	this.userChangedSubscription = this.event.userChanged$.subscribe(()=>{
		this.storage.get('user').then((val) => {
		  this.user= val;
		  if(val){
			this.fullname = val.fullname.replace(/ .*/,'');
		  }

		 })
	  })
  }

	@HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
		  this.screenWidth = window.innerWidth;

          if(this.screenWidth<300) {
			this.screen=0;
			
		   }else if (this.screenWidth>=300  && this.screenWidth<425){
            this.screen=1;
          }else if (this.screenWidth>=425  && this.screenWidth<=800){
            this.screen=2;
		  }else if (this.screenWidth>800 || this.screenWidth == null || !this.screenWidth){
            this.screen=3;
          }
	}



  async modalTerms(number) {
		let modal;
		  modal = await this.modalCtrl.create({
			component: TermsComponent,
			componentProps: {terms:number, lang: this.language},
		  });
		  await modal.present();
	
	
	
		modal.onDidDismiss().then((data) => {
		  if (data !== null) {
			this.ngOnInit();
		  }
		});
	
	  }




       routeTo(a){
        this.route.navigateByUrl(a);
 }
  
}
