import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ExpandableComponent } from './expandable/expandable.component';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { LangPopoverComponent } from './lang-popover/lang-popover.component';
import { TextHelperComponent } from './text-helper/text-helper.component';
import { PipeModule } from '../pipes/pipe.module';
import { PremiumComponent } from './premium/premium.component';
import { TermsComponent } from './terms/terms.component';
import { AirTrackingComponent } from './air-tracking/air-tracking.component';
import { InvoiceDataComponent } from './invoice-data/invoice-data.component';
import { TutorialMarkComponent } from './tutorial-mark/tutorial-mark.component';
import { ShellModule } from '../shell/shell.module';
import { StaticBarComponent } from './static-bar/static-bar.component';
import { ResultadosComponent } from './resultados/resultados.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ShellModule,
    IonicModule,
    PipeModule,
  ],
  declarations: [
    StaticBarComponent,
    HeaderComponent,
    FooterComponent,
    ExpandableComponent,
    LangPopoverComponent,
    TextHelperComponent,
    ShowHidePasswordComponent,
    PremiumComponent,
    TermsComponent,
    AirTrackingComponent,
    InvoiceDataComponent,
    TutorialMarkComponent,
    ResultadosComponent

  ],
  exports: [
    StaticBarComponent,
    ShellModule,
    HeaderComponent,
    FooterComponent,
    ExpandableComponent,
    LangPopoverComponent,
    TextHelperComponent,
    ShowHidePasswordComponent,
    PremiumComponent,
    TermsComponent,
    AirTrackingComponent,
    InvoiceDataComponent,
    TutorialMarkComponent,
    ResultadosComponent

  ]
})
export class ComponentsModule {}
