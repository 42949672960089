import { Component, OnInit, Input ,ViewChild} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators} from '@angular/forms';
import { LoadingController, ToastController, ModalController, NavController,IonContent } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { RestPandalaService } from '../../providers/rest-pandala.service';
import { LoadingService } from '../../loading.service';
import { Subscription } from 'rxjs';
import { ExternalService } from '../../providers/external.service';
import { IonDatetime } from '@ionic/angular';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale'




@Component({
  selector: 'app-request-inspection',
  templateUrl: './request-inspection.component.html',
  styleUrls: ['./request-inspection.component.scss'],
})
export class RequestInspectionComponent implements OnInit {
  @ViewChild(IonDatetime, { static: true }) datetime: IonDatetime;

  dateValue = '';
  dateValue2 = '';
	@ViewChild(IonContent, { static: false }) content: IonContent;
  @Input() type: number;
  @Input() reqid: number;
  public numbThumb: any = Array(2);
  public imgList: any = [];
   message:string;
   public numbFiles: any = Array(2);
   public fileList: any = [];

   public numbFilesp: any = Array(1);
   public filepList: any = [];

  public dataForm:UntypedFormGroup;
  public isClock:boolean;
  public uploadImageArray:Array<any>;
  public uploadFilesArray:Array<any>;
  public dayCount:number;
  ustatus:any;
  public routerData:any;
  public catList: any = [];
  public mesuList: any = [];
	public categoriesArray:Array<any>;
  public portsArray:Array<any>;
  ports:any = [];
  language:any;
  user:any;
  start_date:any;
  languageChangedSubscription: Subscription;




  inspection_request:any;
  freight_product_list:any = [];
  verF:any = 0;
  total_moq:number = 0;
  public dataMercaForm: UntypedFormGroup;
  placeholder:string;
  placeholder2:string;
  locale_date:string = 'en-GB';


  freight:any;
  constructor(
    public formBuilder: UntypedFormBuilder,
    public uploadProvider: RestPandalaService,
    public toastCtrl: ToastController,
    public loadingCtrl: LoadingController, 
    public requestProvider: RestPandalaService,
    private storage: Storage, 
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public http: HttpClient,
    public modalCtrl: ModalController,
    public loading: LoadingService,
    public event: ExternalService
  ) { 




    let date_ob = new Date();

  
this.start_date = this.formatDate( this.addDays(date_ob, 2));


   this.storage.get('language').then((val) => {
    this.language= val;

    if(this.language == 'en'){

      this.locale_date ='en-GB';

    }else if(this.language=='es'){

      this.locale_date ='es';
    }else{
      this.locale_date ='en-GB';

    }
    
   })

   this.storage.get('user').then(userResult => {
    this.user = userResult;
    this.ustatus = userResult.status;
    console.log(this.ustatus);
   });
    this.uploadImageArray = [];
    this.uploadFilesArray = [];

    this.dayCount = 0;
    this.dataForm = this.formBuilder.group({
      date: [this.start_date, Validators.required],
      time: ['03:33', Validators.required],
			name: ['', Validators.required],
      address:[''],
      contactname:[''],
      phone:['', Validators.required],
      wechatid:[''],
      email:[''],
			Description:[''],
      thumb: [''],

      timeconfirm: [false],

      safebrand: [false, Validators.pattern('true')],
		});
  }




   formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}




confirm() {


}

reset() {
  // this.datetime.nativeEl.reset();
}

formatDate2(value: string) {

  if(this.language=='es'){

    return format(parseISO(value), 'MMM dd yyyy', {locale:es})
  }else{

    return format(parseISO(value), 'MMM dd yyyy');
  }

}

changeDate(a){
 this.dateValue =  this.formatDate2(a.value);
  a.confirm(true);

}

  ngOnInit() {
this.type;


this.storage.get('user').then(userResult => {

  var params = {uid: userResult.id, ucountry:userResult.country_id, lang: this.language};

  this.requestProvider.getNewRequestData(params).subscribe(obj => {

   if(obj['code']==1){

    this.ports=obj['fflist'].ports;
    this.mesuList=obj['fflist'].measures;

   }else{
     this.loading.dismiss();
     alert(obj['msg']);
   }
  
      });




    });



this.dataMercaForm = this.formBuilder.group({
  m_description: ['', Validators.compose([Validators.required])],
  m_name: ['', Validators.compose([Validators.required])],
  m_style: ['', Validators.compose([Validators.required])],
  m_orderno: [''],
  m_moq: [, Validators.compose([Validators.required])],
  isfull: [false],
  m_moq_type: [0, Validators.compose([Validators.required])],
  thumb: [''],

});

    this.languageChangedSubscription = this.event.languageChanged$.subscribe(()=>{
      this.storage.get('language').then((val) => {
        this.language= val;
       })
      })
     


		this.routerData = this.activatedRoute.params['value'];
    if (this.routerData && this.routerData.type) {
      let productImages = this.routerData.productImage.split(',');
      let productFiles = this.routerData.productFiles.split(',');
      this.uploadImageArray = productFiles;
      this.uploadFilesArray = productImages;
      this.dataForm.patchValue({
        category: this.routerData.category,
			  type: this.routerData.type,
			  name: this.routerData.name,
        brand: this.routerData.brand,
        productImage: productImages,
			  Description: this.routerData.Description,
				priceRange: JSON.parse(this.routerData.priceRange),
				moq: this.routerData.moq,
        tradeTerms: this.routerData.tradeTerms,
        container: this.routerData.container
      });
    }
	}
	









  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  sendEmail(id){
    var lang;
    if(this.language == null || !this.language == null || this.language == 'en'){
      lang = 'en';
    } else if(this.language == 'es'){
      lang = 'es';
    }

    this.http.get('https://pandala.com.cn/restgt/index.php/Pandala/emailreq/'+id+'/'+lang).subscribe(() => {
    });
  }


  imgPreview(event, i) {
    if (event.target.files === 0) {
      return;
    }
    var file = event.target.files[0];
    let mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    console.log(this.imgList);
    if (!this.imgList[i]) {
      reader.onload = (_event) => {
        this.imgList.push({ url: reader.result, path: file });
        event.target.value = null;
      };
    }
    if (this.imgList[i]) {
      reader.onload = (_event) => {
        this.imgList[i].path = file;
        this.imgList[i].url = reader.result;
        event.target.value = null;
      };
    }
  }

  imgRemove(i) {
    this.imgList.splice(i, 1);
  }



  filePreview(event, i) {
    if (event.target.files === 0) {
      return;
    }
    var file = event.target.files[0];
    let mimeType = file.type;
    var file_type;
    console.log(mimeType)
    if (mimeType.match(/image\/*/) != null) {
      // this.message = 'Only files are supported.';
      file_type = 'image';
      // alert(this.message);
      // return;
    }else{
      file_type = 'file';
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    console.log(this.fileList);
    if (!this.fileList[i]) {
      reader.onload = (_event) => {
        this.fileList.push({ url: reader.result, path: file, type:file_type });
        event.target.value = null;
      };
    }
    if (this.fileList[i]) {
      reader.onload = (_event) => {
        this.fileList[i].path = file;
        this.fileList[i].url = reader.result;
        event.target.value = null;
      };
    }
  }

  fileRemove(i) {
    console.log(i);
    this.fileList.splice(i, 1);
  }



  
  filepPreview(event, i) {
    if (event.target.files === 0) {
      return;
    }
    var file = event.target.files[0];
    let mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    console.log(this.filepList);
    if (!this.filepList[i]) {
      reader.onload = (_event) => {
        this.filepList.push({ url: reader.result, path: file });
        event.target.value = null;
      };
    }
    if (this.filepList[i]) {
      reader.onload = (_event) => {
        this.filepList[i].path = file;
        this.filepList[i].url = reader.result;
        event.target.value = null;
      };
    }
  }

  filepRemove(i) {
    this.filepList.splice(i, 1);
  }


submitForm() {
this.loading.present();


for (let index = 0; index < this.fileList.length; index++) {
  const element = this.fileList[index];
  if (index == 0) {
    this.dataForm.value['thumb'] = element.url;
  } else {
    this.dataForm.value['thumb' + index] = element.url;
  }
}


		if(!this.dataForm.valid) {
      if(this.language =='en'){
        this.presentToast('Please, complete all the required information before submit your request.');
      }else if(this.language=='cn'){
        this.presentToast('Please, complete all the required information before submit your request.');
      }else if(this.language=='es'){
        this.presentToast('Valida tu formulario. Se requiere más información para iniciar el proceso de inspección.');
      }
 
         this.loading.dismiss();

    } else if(this.routerData && this.routerData.type){
      this.loading.dismiss();
    } else if (this.dayCount >= 5) {
      if(this.language =='en'){
        this.presentToast('Sorry, you have reached the maximun daily requests.');
      }else if(this.language=='cn'){
        this.presentToast('Sorry, you have reached the maximun daily requests.');
      }else if(this.language=='es'){
        this.presentToast('Lo sentimos, has alcanzado el máximo de solicitudes.');
      }

      this.loading.dismiss();

    } else {
      this.dayCount = 0;  
      this.storage.get('user').then(userResult => {

            
            this.dataForm.value.userId = userResult.id;
            this.dataForm.value.created = Date();
            this.dataForm.value.productImage = this.uploadImageArray;
            this.dataForm.value.productFiles = this.uploadFilesArray;
            this.dataForm.value.requestId = this.reqid;
            this.dataForm.value.type = this.type;

              console.log(this.dataForm.value); 


              this.requestProvider.sendInspectionB(this.dataForm.value, this.fileList).subscribe((data: any) => {
            
              console.log(data);
              if(data['error'] == false ){



                this.loading.dismiss().then(() => {





                  this.fileList = [];


                  
                  // this.sendEmail(data['id']);
  
                  if(this.router.url != '/tabs/inspection'){ 
                    this.router.navigate(['/tabs/inspection']);
                  }
                  this.loading.dismiss();
                  this.closeModal();
  
                  if(this.language =='en'){
                    this.presentToast('The Inspection request has been successfully created!');
                  }else if(this.language=='cn'){
                    this.presentToast('The Inspection request has been successfully created!');
                  }else if(this.language=='es'){
                    this.presentToast('La Solicitud de inspección ha sido enviada con éxito!');
                  }
  
  
                
  
  
  
                  
  
                  this.closeModal();
                
                });

              }else{

                if(this.language =='en'){
    
                }else if(this.language=='cn'){
                  this.presentToast('Something went wrong with your request. Please, contact us as soon as you can.');
                }else if(this.language=='es'){
                  this.presentToast('El envío de la solicitud ha fallado!');
                }
                this.loading.dismiss();
                this.closeModal();
         

              }
           
              });
          
      });
		}
  }
  



  async presentToast(mess:string) {
		const toast = await this.toastCtrl.create({
      message: '<b>'+mess+'</b>',
			duration: 4500,
      position: 'middle',
      color: 'light'
		});
    toast.present();
  }




  public formatTime(date, fmt) {
    var o = {
      "M+": date.getMonth() + 1, 
      "d+": date.getDate(), 
      "h+": date.getHours(),
      "m+": date.getMinutes(), 
      "s+": date.getSeconds(), 
      "q+": Math.floor((date.getMonth() + 3) / 3), 
      "S": date.getMilliseconds()
    };
    if(/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for(var k in o)
      if(new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
	}


  public flete($event) {
		let value = $event.detail.value;
     this.freight = value;
     console.log(this.freight);
	}

	
	public minChange($event) {
		let value = $event.detail.value;
		let temp = this.dataForm.value.priceRange;
    temp.lower = value;
		this.dataForm.patchValue({
			priceRange: temp
		})
	}

	public maxChange($event) {
		let value = $event.detail.value;
		let temp = this.dataForm.value.priceRange;
		temp.upper = value;
		this.dataForm.patchValue({
			priceRange: temp
		})	
	}


  /**
   * Find in the data whether there is a suitable product
   * Also consider adding multiple matching conditions, not just names, such as type, or categroy
   * @param {string} userId 
   * @param {string} requestId 
   * @memberof SendRequestPage
   */



  async presentToast2(msg) {
		const toast = await this.toastCtrl.create({
			message: msg,
			duration: 8000,
			position: 'middle',
			cssClass: 'toast-app'
		});
		toast.present();
  }

  async presentToast3(msg) {
		const toast = await this.toastCtrl.create({
			message: msg,
			duration: 5500,
			position: 'middle',
			cssClass: 'toast-app'
		});
		toast.present();
  }
  

  help1(msg) {

    this.presentToast2(msg);
  }

  help2(msg) {

    this.presentToast3(msg);
  }


  async  closeModal() {
    this.modalCtrl.dismiss(undefined, undefined, 'inspection-request');
  }


  //FORM DESIGN


  verForm(){
    this.verF=1;
  }



  removeProduct(i,id_p){




this.loading.present();


    this.requestProvider.deleteProductInspection(id_p).subscribe((data: any) => {

    if(data['error'] == false ){
      this.loading.dismiss().then(() => {


        this.storage.set('inspection_goods_list', this.freight_product_list).then((val) => {
          this.total_moq = 0;
  

          this.storage.get('inspection_goods_list').then((val) => {
            console.log(val);
            if(!val || val == null){
              this.freight_product_list = new Array();
            }else{
              this.freight_product_list = val;
                for (var i = 0; i < this.freight_product_list.length; ++i) {
                  this.total_moq += this.freight_product_list[i].m_moq ;
   
                  
      
                  
      
            
                  }
      
              
                  
                 
      
            }
      
      
          });
          })


        this.loading.dismiss();

      });

    }else{


      this.loading.dismiss();


    }
 
    });

		
    this.freight_product_list.splice(i, 1);





          





}

  calcular(){
		this.total_moq= 0;
		this.freight_product_list.forEach((val) => {
			let item_tmp:number = 0;

			

		item_tmp = Number(val.moq);
		
		
			this.total_moq = this.total_moq + item_tmp;

		});

	}




  NoverF(){
    this.verF=0;
  }




  submitMercaForm(){

this.loading.present();
    for (let index = 0; index < this.filepList.length; index++) {
      const element = this.filepList[index];
      if (index == 0) {
        this.dataMercaForm.value['thumb'] = element.url;
      } else {
        this.dataMercaForm.value['thumb' + index] = element.url;
      }
    }

    
		if(!this.dataMercaForm.valid) {
      this.loading.dismiss();
      alert('Todos los campos son requeridos.')
     
         }else{


    var itemCv = {
      m_description: this.dataMercaForm.value.m_description, 
      m_moq: this.dataMercaForm.value.m_moq,
      m_name: this.dataMercaForm.value.m_name,
      m_style: this.dataMercaForm.value.m_style,
      m_moq_type: this.dataMercaForm.value.m_moq_type,
      isfull: this.dataMercaForm.value.m_moq_type,
      id: '',
      thumb: '',
    }
  




    this.storage.get('user').then(userResult => {

          
          this.dataMercaForm.value.userId = userResult.id;
          this.dataMercaForm.value.created = Date();
          this.dataMercaForm.value.productImage = this.uploadImageArray;
          this.dataMercaForm.value.requestId = this.reqid;

            console.log(this.dataMercaForm.value); 


            this.requestProvider.sendProductInspect(this.dataMercaForm.value, this.filepList).subscribe((data: any) => {
          
              console.log(data);

            if(data['error'] == false ){


      

              this.loading.dismiss().then(() => {

                itemCv.id= data.id;
                itemCv.thumb= data.thumb;
                this.freight_product_list.push(itemCv);
                console.log(this.freight_product_list);


                this.storage.set('inspection_goods', this.inspection_request).then((val) => {
                this.inspection_request = [];
                this.total_moq = 0;
            
                  this.storage.set('inspection_goods_list',this.freight_product_list).then((val2) => {
                  
                    this.verF=0;
                    if(!val2 || val2 == null){
                      this.freight_product_list = new Array();
                    }else{
                      this.freight_product_list = val2;
 
                        for (var i = 0; i < this.freight_product_list.length; ++i) {
                          this.total_moq += this.freight_product_list[i].m_moq ;

            
                          }
            
            
                    }
                     
                  })
             
                   
                })
            





                this.filepList = [];
                this.dataMercaForm = null;
                this.dataMercaForm = this.formBuilder.group({
                  m_description: ['', Validators.compose([Validators.required])],
                  m_name: ['', Validators.compose([Validators.required])],
                  m_style: ['', Validators.compose([Validators.required])],
                  m_orderno: [''],
                  m_moq: [, Validators.compose([Validators.required])],
                  isfull: [false],
                  m_moq_type: [0, Validators.compose([Validators.required])],
                  thumb: [''],
                
                });


                

                this.loading.dismiss();

                // if(this.language =='en'){
                //   this.presentToast('The Request has been successfully created!');
                // }else if(this.language=='cn'){
                //   this.presentToast('The Request has been successfully created!');
                // }else if(this.language=='es'){
                //   this.presentToast('Solicitud enviada con éxito!');
                // }
              });

            }else{

              // if(this.language =='en'){
  
              // }else if(this.language=='cn'){
              //   this.presentToast('Something went wrong with your request. Please, contact us as soon as you can.');
              // }else if(this.language=='es'){
              //   this.presentToast('El envío de la solicitud ha fallado!');
              // }
              this.loading.dismiss();
       

            }
         
            });

    });


  }
  }
  
}





