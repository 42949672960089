// import { Injectable } from '@angular/core';
// import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
// import { AngularFirestore } from '@angular/fire/firestore';
// import * as firebase from 'firebase/app';
// import { Observable } from 'rxjs';
// import { map } from 'rxjs/operators';
// import { finalize } from 'rxjs/operators';

// @Injectable()
// export class UploadProvider {

//   uploadProgress: any;
//   task: any;
//   ref: any;
  
//   uploadPercent: Observable<number>;
//   downloadURL: Observable<string>;

//   constructor(private afStorage: AngularFireStorage, private db: AngularFirestore) { }

//   upload(file, folderName) {
//     const randomId = Math.random().toString(36).substring(2);

//     const storageRef = firebase.storage().ref();
//     const uploadTask = storageRef.child('/'+folderName+'/'+randomId).put(file);
     
//     return new Promise<any>((resolve, reject) => {
//       uploadTask.on(
//         firebase.storage.TaskEvent.STATE_CHANGED,
//         (snapshot) => {},
//         (error) => {},
//         () => {
//           uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
//             this.downloadURL = downloadURL;
//             console.log('File available at', downloadURL);
//             resolve(this.downloadURL);
//           });
//         }
//       );
//     });

//   }

//   // private deleteFileStorage(name: string) {
//   //   const storageRef = firebase.storage().ref();
//   //   storageRef.child(`${this.basePath}/${name}`).delete();
//   }
  
// }


import { Injectable } from '@angular/core';
import { doc, docData, Firestore, setDoc } from '@angular/fire/firestore';
import { getDownloadURL, ref, Storage, uploadString } from '@angular/fire/storage';
import { Photo } from '@capacitor/camera';
import { RestPandalaService } from './rest-pandala.service';
import { Storage as Storagex }  from '@ionic/storage';
@Injectable({
	providedIn: 'root'
})
export class UploadService {
	constructor(private firestore: Firestore, private storage: Storage, public rest: RestPandalaService, public storex: Storagex) {}


	async uploadImageReq(cameraFile: Photo, userid, counterImg, idref) {
	

		// console.log(idref)
    const uid = userid;
		const path = `sourcing/${uid}/${idref}-${counterImg+1}.webp`;
		const storageRef = ref(this.storage, path);

		try {
			await uploadString(storageRef, cameraFile.base64String, 'base64');

			const imageUrl = await getDownloadURL(storageRef);
      const uid = userid;
			const userDocRef = doc(this.firestore, `id-req/${uid}/`);
      

			await setDoc(userDocRef, {
				imageUrl
			});
      //tencent handling
    //   console.log(imageUrl);
      const url = encodeURIComponent(imageUrl);
	  const todo = 3;
  

 
//be careful id_ref (location - timezone)

      this.rest.updateFile({url:url, todo:todo, uid: uid,id: idref}).subscribe(data => {

    // console.log(data);
	var ncounterImg = counterImg +1;
	
	
	this.storex.set('request_img_'+ncounterImg,imageUrl).then(val => {


		// console.log(this.storex.get('request_img_'+ncounterImg));
	

		return true;
  
  
  
		  });


  
    });


    
		} catch (e) {
			return false;
		}
	}


	async uploadImageBack(cameraFile: Photo, userid) {
	
		const uid = userid;
		const path = `uploads/${uid}/backup.webp`;
		const storageRef = ref(this.storage, path);

		try {
			await uploadString(storageRef, cameraFile.base64String, 'base64');

			const imageUrl = await getDownloadURL(storageRef);
      const uid = userid;
			const userDocRef = doc(this.firestore, `backup-img/${uid}/`);
      

			await setDoc(userDocRef, {
				imageUrl
			});
      //tencent handling
    //   console.log(imageUrl);
      const url = encodeURIComponent(imageUrl);

      const id = null;
  

    //   await this.rest.updateFile({url:url, todo: todo, id: id, uid: uid})
    //   return true;

      this.rest.updateFile({url:url, todo: 2, id: id, uid: uid}).subscribe(data => {

    // console.log(data);
window.location.reload();
    return true;
  
    });


    
		} catch (e) {
			return false;
		}
}






async uploadImageId(cameraFile: Photo, userid) {
	
    const uid = userid;
		const path = `uploads/${uid}/id.webp`;
		const storageRef = ref(this.storage, path);

		try {
			await uploadString(storageRef, cameraFile.base64String, 'base64');

			const imageUrl = await getDownloadURL(storageRef);
      const uid = userid;
			const userDocRef = doc(this.firestore, `id-img/${uid}/`);
      

			await setDoc(userDocRef, {
				imageUrl
			});
      //tencent handling
    //   console.log(imageUrl);
      const url = encodeURIComponent(imageUrl);
      const todo = 1;
      const id = null;
  

    //   await this.rest.updateFile({url:url, todo: todo, id: id, uid: uid})
    //   return true;

      this.rest.updateFile({url:url, todo: todo, id: id, uid: uid}).subscribe(data => {

    // console.log(data);
window.location.reload();
    return true;
  
    });


    
		} catch (e) {
			return false;
		}
	}


}	