// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    projectId: 'pandala-global',
    appId: '1:302518989503:web:b308052dd7391315210c0a',
    storageBucket: 'pandala-global.appspot.com',
    apiKey: 'AIzaSyBKLO74gziDKBX7nfIkDejLwBzpnY3j1hg',
    authDomain: 'pandala-global.firebaseapp.com',
    messagingSenderId: '302518989503',
    measurementId: 'G-YX54TTRDMG',
  },
  appShellConfig: {
    debug: false,
    networkDelay: 500
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
