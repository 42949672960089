import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import { HttpClient } from '@angular/common/http';


	

Injectable({
  providedIn: 'root'
})


declare var AMap;

	@Injectable()

	

export class MapService {

	private map = null;
	private element:Element = null;
	marker:Array<any> = [];
	windowsArr:Array<any> = [];

	

	
  private earthR = 6378245.0; // 地球半径

	constructor( private http: HttpClient) {}




public getLineDistance(i_lat,i_lng,p_lat,p_lng):Observable<any>{
  return new Observable((sub:any) => {
  

    console.log(i_lat);
    console.log(i_lng);
    console.log(p_lat);
    console.log(p_lng);


  var d1 = 0.01745329251994329;
                var d2 = i_lng;
                var d3 = i_lat;
                var d4 = p_lng;
                var d5 = p_lat;
                d2 *= d1;
                d3 *= d1;
                d4 *= d1;
                d5 *= d1;
                var d6 = Math.sin(d2);
                var d7 = Math.sin(d3);
                var d8 = Math.cos(d2);
                var d9 = Math.cos(d3);
                var d10 = Math.sin(d4);
                var d11 = Math.sin(d5);
                var d12 = Math.cos(d4);
                var d13 = Math.cos(d5);
                var arrayOfDouble1 = [];
                var arrayOfDouble2 = [];
                arrayOfDouble1.push(d9 * d8);
                arrayOfDouble1.push(d9 * d6);
                arrayOfDouble1.push(d7);
                arrayOfDouble2.push(d13 * d12);
                arrayOfDouble2.push(d13 * d10);
                arrayOfDouble2.push(d11);
                var d14 = Math.sqrt((arrayOfDouble1[0] - arrayOfDouble2[0]) * (arrayOfDouble1[0] - arrayOfDouble2[0]) +
                    (arrayOfDouble1[1] - arrayOfDouble2[1]) * (arrayOfDouble1[1] - arrayOfDouble2[1]) +
                    (arrayOfDouble1[2] - arrayOfDouble2[2]) * (arrayOfDouble1[2] - arrayOfDouble2[2]));
                 var distance = Math.asin(d14 / 2.0) * 12742001.579854401;
                 console.log(distance);
                    sub.next(distance);
                    sub.complete();
                  });


}


public getTrackRoute(r_coord:any, u_coord:any):Observable<any> {
  return new Observable((sub:any) => {
  var key = '0fe0f388f61d65ad164079662f3357c0';  //ac73c0c62a02e031151389b96e2db437
  var origin = u_coord;
  var destination = r_coord;
      var url = "https://restapi.amap.com/v3/direction/driving?origin="+origin+"&destination="+destination+"&key=aab910e2bc525fb14d620dd8d03c1566&size=4&nosteps=1&extensions=all";
      var send = '';
      var xhr = new XMLHttpRequest();
      xhr.overrideMimeType("application/json");
    xhr.open("GET",url);
    xhr.send(send);
    xhr.onreadystatechange = function(){
      console.log(xhr);
      if(xhr.readyState === 4 && xhr.status == 200){
        console.log('success');
              var res = JSON.parse(xhr.response);   
              sub.next(res.route.paths[0].distance);
              sub.complete();
      }else{
        console.log(res);
      }
    }
   
  });
  }


}
