import { Component, OnInit, Input } from '@angular/core';
import { ViewEncapsulation  } from '@angular/core';

@Component({
  selector: 'app-text-helper',
  templateUrl: './text-helper.component.html',
  styleUrls: ['./text-helper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TextHelperComponent implements OnInit {
  @Input() html; 

  constructor() { }

  ngOnInit() {}

}
