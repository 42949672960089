import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafehtmlPipe } from './safehtml.pipe';
import { RelativeTimePipe } from './relative-time.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    SafehtmlPipe,
    RelativeTimePipe
  ],
  declarations: [
    SafehtmlPipe,
    RelativeTimePipe
  ]
})
export class PipeModule { }