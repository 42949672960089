import { Component,OnInit, OnDestroy, ViewChild} from '@angular/core';
import { Platform, MenuController, ToastController, NavController, ModalController} from '@ionic/angular';
import {Observable} from 'rxjs';
import { Storage } from '@ionic/storage-angular';
import { Router, ActivatedRoute } from '@angular/router';
import { RestPandalaService } from './providers/rest-pandala.service';
import {Subscription} from 'rxjs';
import { ExternalService } from './providers/external.service';
import { SeoService } from './utils/seo/seo.service';
import { HistoryHelperService } from './utils/history-helper.service';
import { TutorialMarkComponent } from './components/tutorial-mark/tutorial-mark.component';
import { IonContent } from '@ionic/angular';

import { register } from 'swiper/element/bundle';
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [
    './styles/side-menu.scss',
    './styles/side-menu.shell.scss',
    './styles/side-menu.responsive.scss'
  ]
})
export class AppComponent {


  @ViewChild(IonContent) content: IonContent;
  showContactBar = true;
  
  public appPages = [
    // {
    //   id: 1,
    //   title: 'Home',
    //   title_cn: '主页',
    //   title_sp: 'Inicio',
    //   url: 'tabs/home',
    //   icon:  'assets/imgs/home.png',
    //   icon2: 'assets/imgs/home.png'
    // },
    {
      id: 1,
      title: 'Sourcing Requests',
      title_cn: '我的订单',
      title_sp: 'Solicita Mercancía',
      url: '/tabs/sourcing',
      icon:  'assets/imgs/sourcing.png',
      icon2:  'assets/imgs/sourcing.png'
    },
    {
      id: 2,
      title: 'Inspection Request',
      title_cn: '我的订单',
      title_sp: 'Agenda Inspección',
      url: '/tabs/inspection',
      icon:  'assets/imgs/inspect.png',
      icon2:  'assets/imgs/inspect.png'
    },
    {
      id: 3,
     title: 'Quotation list',
      title_cn: '我的订单表',
      title_sp: 'Listado de Cotización',
      url: '/mycart',
      icon:  'assets/imgs/quotes.png',
      icon2:  'assets/imgs/quotes.png'
    },
    {
      id: 4,
      title: 'My Orders',
      title_cn: '我的订单',
      title_sp: 'Pedidos',
      url: 'tabs/myorder',
      icon:  'assets/imgs/orders.png',
      icon2:  'assets/imgs/orders.png'
    },
    {
      id: 5,
      title: 'Favorites',
      title_cn: '收藏夹',
      title_sp: 'Favoritos',
      url: '/favorites',
      icon:  'assets/imgs/favo.png',
      icon2: 'assets/imgs/favo.png'
    },{
      id: 6,
      title: 'Workflow',
      title_cn: '收藏夹',
      title_sp: 'Seguimiento de Trabajo',
      url: '/workflow',
      icon:  'assets/imgs/followup.png',
      icon2: 'assets/imgs/followup.png'
    },
    // {
    //   id: 6,
    //   title: 'Shipping',
    //   title_cn: 'Shipping',
    //   title_sp: 'Mis Envios',
    //   url: '/shipping',
    //   icon: 'boat-outline',
    //   icon2: 'boat'
    // },
    // {
    //   id: 7,
    //   title: 'FAQ',
    //   title_cn: 'FAQ',
    //   title_sp: 'Preguntas Frecuentes',
    //   url: '/faq',
    //   icon: 'help-outline',
    //   icon2: 'help'
    // },
  
  ];

  textDir = 'ltr';
  activePath = '';

  private data: Observable<any>;
  user: any;
  userId: any;
  isPrem: boolean;
  show: number = 0;
  cat_list:any[]=[];
  flag_list:any[]=[];
  name:any;
  language:any = null;



  languageChangedSubscription: Subscription;
   userChangedSubscription: Subscription;

  constructor(
    public event: ExternalService,
    private platform: Platform,
    public menuCtrl: MenuController,
    private storage: Storage,
    public navCtrl: NavController,
    public toastCtrl: ToastController,
    public modalCtrl: ModalController,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private _restPandala : RestPandalaService,
    public historyHelper: HistoryHelperService,
    private seoService: SeoService

  ) {


  
   // this.events.subscribe('language: change', (lst) => {
     // this.language = lst;
    //});



    //this._restPandala.getCategories().subscribe(data => {
    // this.cat_list = data;
   // })


   // this._restPandala.getProductsbyCountry().subscribe(data => {
   //   this.flag_list = data;
   //  })

  

   // this.events.subscribe('user: change', (user) => {
      //if (user || user != null) {
      //    this.user = user;
      //    this.name = user.fullname.replace(/ .*/,'');
     //   console.log(this.name);
          //this.router.navigateByUrl('home');
    //  } else {
    //    this.user=null;
         // this.router.navigateByUrl('signup');
   //   }
  //});


  







    this.initializeApp();
  }

  ngOnDestroy(): void {
    if (this.languageChangedSubscription) {
      this.languageChangedSubscription.unsubscribe();
    }
  }



  async ngOnInit() {

    // console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
    var offset = new Date().getTimezoneOffset();
  // console.log(offset);


    await this.storage.create();
    this.storage.set('timeZone',Intl.DateTimeFormat().resolvedOptions().timeZone);
    this.storage.set('timeZoneOffSet',new Date().getTimezoneOffset());
    this.storage.get('user').then((val) => {


      if (val != null) {
         this.isPrem = val.isPremium;
          this.user = val;
          this.name = val.fullname.replace(/ .*/,'');

      } else { 

      }
  })
    this.languageChangedSubscription = this.event.languageChanged$.subscribe(()=>{
      this.storage.get('language').then((val) => {
        this.language= val;
       })
    })

    this.userChangedSubscription = this.event.userChanged$.subscribe(()=>{
      this.storage.get('user').then((val) => {
        this.user = val;
        if(val){
          this.name = val.fullname.replace(/ .*/,'');
        }
      
       })
    })
  }




	toEnglish(){
 //   this.events.publish('language: change', null);
		this.storage.set('language', 'en').then(() => {
      this.event.updateLanguage('en');
      //this.menuCtrl.enable(false);
      //this.navCtrl.navigateRoot('/splash');



});



	  
	  }
	  
	  toChinese(){
    //  this.events.publish('language: change', 'cn');
		this.storage.set('language', 'cn').then(() => {
      this.event.updateLanguage('cn');
	  });
	}
	  
	  
	  toSpanish(){
    //  this.events.publish('language: change', 'sp');
		this.storage.set('language', 'es').then(() => {
      this.event.updateLanguage('es');
	  });
	}

  goSign(){
this.navCtrl.navigateRoot('/tabs/account');
this.menuCtrl.close();
  }

  ngAfterViewInit() {

    this.storage.get('language').then((val) => {

      if(val){
        this.language= val;
      }else{
        this.language= 'es';
        this.storage.set('language',this.language);
      }
    
     })
  }

  async toModal() {
    if(this.user){
      var uid = this.user.id;
    }else{
      var uid = null;
    }
    
      let modal;
        modal = await this.modalCtrl.create({
          component: TutorialMarkComponent,
          componentProps: {'language':this.language, 'uid': uid},
        });
        await modal.present();
  
  
  
      modal.onDidDismiss().then((data) => {
        if (data !== null) {
          // this.ngOnInit();
        }
      });
  
    }

  initializeApp() {
    this.platform.ready().then(() => {

    });
  }
}
