import { Component, OnInit, Input } from '@angular/core';
import { ModalController , AlertController} from '@ionic/angular';
import { RestPandalaService } from '../../providers/rest-pandala.service';
import { ExternalService } from '../../providers/external.service';
import { LoadingService } from '../../loading.service';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.scss'],
})
export class BankDetailsComponent implements OnInit {

  @Input() language;
  @Input() uid;
  @Input() ucountry;
  ready:boolean = false;
   bankDetails:any;
  ready_d:number=0;
  beneficiario:any;
  banco:any;
  direccion:any;
  cuenta:any;
  swift:any;



    constructor(public loading: LoadingService, public alertCtrl:AlertController, public event: ExternalService, public modalController: ModalController, public alertController: AlertController, private _restPandala: RestPandalaService) {
      setTimeout( () => {
        this.ready=true;
   }, 2000);


     }


  
    ngOnInit() {

this.loading.present();

  var params = {lang:this.language, ucountry:this.ucountry};
  this._restPandala.getBankDetails(params).subscribe(data => {
     console.log(data)
     this.bankDetails = data['fflist'][0].content;
     this.beneficiario = data['fflist'][0].beneficiary;
     this.banco = data['fflist'][0].bank;
     this.direccion = data['fflist'][0].address;
     this.cuenta = data['fflist'][0].account;
     this.swift = data['fflist'][0].swift;
   this.ready_d =1;
   this.loading.dismiss();
   })



    }
    
    validPurchase(id) {
      // Purchase confirm 
      //Do whatever you want to do
    }


    ionViewDidEnter() {

    }












    dismissModal(){
      this.modalController.dismiss();
    }
  }