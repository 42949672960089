import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators} from '@angular/forms';
import { LoadingController, ToastController, ModalController, NavController , AlertController} from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { RestPandalaService } from '../../providers/rest-pandala.service';
import { LoadingService } from '../../loading.service';
import { Subscription } from 'rxjs';
import { ExternalService } from '../../providers/external.service';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { UploadService } from '../../providers/upload.service';


@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss'],
})
export class RequestComponent implements OnInit {
  @Input() type: number;
  public numbThumb: any = Array(3);
  public imgList: any = [];
   message:string;
reqid:string ='';
reqid_show:string = '';
   ready:number =0;
  public dataForm:UntypedFormGroup;
  public isClock:boolean;
  public uploadImageArray:Array<any>;
  public dayCount:number;
  ustatus:any;
  public routerData:any;
  public catList: any = [];
  public workTask: any = [];
  public mesuList: any = [];
	public categoriesArray:Array<any>;
  public portsArray:Array<any>;
  ports:any = [];
  storageList:any = [];
  language:any;
  user:any;
  languageChangedSubscription: Subscription;
  is_container:boolean = false;
  selectedMeasure:string = '';
  selectedMeasures:string = '';
  freight:any;
  
  phold_type:string;
  phold_details:string;
  phold_name:string;
  phold_supplier:string;
  phold_moq:string;
  phold_moq_s:string;
  phold_storage:string;
  phold_port:string;
  phold_typef: string;
  phold_cat: string;
  phold_task:string;

  counterImg: number = 0;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public uploadProvider: RestPandalaService,
    public toastCtrl: ToastController,
    public loadingCtrl: LoadingController, 
    public requestProvider: RestPandalaService,
    private storage: Storage, 
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public http: HttpClient,
    public modalCtrl: ModalController,
    public loading: LoadingService,
    public event: ExternalService,
    private uploadService: UploadService,
    public alertCtrl: AlertController,
  ) { 
   // this.portsArray = chinesePorts;
   this.storage.get('language').then((val) => {
    this.language= val;
    if(this.language=='es'){
      this.phold_type='Resume la mercancía que vas a importar en simples palabras';
      this.phold_details='Por favor, proporciona detalles como el material, las medidas o dimensiones, el tipo de uso, el paquete o empaquetado, la personalización o diseño, o cualquier otra información relevante que consideres importante compartir con nosotros.';
      this.phold_name= 'Nombre clave de la mercancía que buscas';
      this.phold_supplier='Ingresa el nombre del proveedor en el caso de conocerlo';
      this.phold_moq='Cantidad a solicitar';
      this.phold_moq_s='Selecciona unidad de medida';
      this.phold_storage='Selecciona una bodega';
      this.phold_port='Selecciona el puerto de destino';
      this.phold_typef='Selecciona servicio de flete';
      this.phold_cat='Selecciona una categoría';
      this.phold_task='Ver mis ordenes de trabajo'

    }else{
      this.phold_type='';
      this.phold_details ='Please enter details like: Material, Size/Dimension, Application, Packaging/Packing or any other requirements';
      this.phold_name = 'Keyword/product name of products you are looking for';
      this.phold_supplier="Enter the manufacturer's name if you know it";
      this.phold_moq='Enter your order quantity';
      this.phold_moq_s='Select type of measurement';
      this.phold_storage='Select one of our warehouse';
      this.phold_port='Select the port of destination';
      this.phold_typef='Select freight service';
      this.phold_cat='Select category';
            this.phold_task='Check my work orders'
    }
   })

   this.storage.get('user').then(userResult => {
    this.user = userResult;
    this.ustatus = userResult.status;
    // console.log(this.ustatus);
   });
    this.uploadImageArray = [];

    this.dayCount = 0;
    this.dataForm = this.formBuilder.group({
      category: ['', Validators.required],
			name: ['', Validators.required],
      brand: [''],
      mesu: [''],
			Description:[''],
			priceRange:[{lower:0,upper:0}],
			moq:[''],
      tradeTerms: ['EXW',Validators.required],
      container: [''],
      strategy: [''],
      port:[''],
      storage:[''],
      thumb: [''],
      companyId: [0],
      id_task:[''],
      url1:[''],
      url2:[''],
      url3:[''],
      img1:[''],
      img2:[''],
      img3:[''],
      sameas: [false],
      hasbat: [false],
      safegoods: [false, Validators.pattern('true')],
      safebrand: [false, Validators.pattern('true')],
		});
  }


  ngOnInit() {
    this.storage.remove('request_img_1');
    this.storage.remove('request_img_2');
    this.storage.remove('request_img_3');
    this.counterImg = 0;
this.reqid = this.requestProvider.createRequestId();
this.reqid_show =  this.reqid.slice(-10);
this.loading.present();
    this.languageChangedSubscription = this.event.languageChanged$.subscribe(()=>{
      this.storage.get('language').then((val) => {
        if(val){
          this.language= val;
        }else{
          this.language= 'en';
        }
       
       })
      })



      this.storage.get('user').then(userResult => {

      var params = {uid: userResult.id, ucountry:userResult.country_id, lang: this.language};

      this.requestProvider.getNewRequestData(params).subscribe(obj => {
       // console.log(obj);
       if(obj['code']==1){

        this.catList=obj['fflist']['categories'];
        if(obj['fflist']['tasks']){
          this.workTask=obj['fflist']['tasks'];
        }else{
          this.workTask=[];
        }
    
        // console.log(this.catList);
        this.ports=obj['fflist'].ports;
        this.mesuList=obj['fflist'].measures;
        this.storageList=obj['fflist'].storages;
        this.loading.dismiss();
        this.ready=1;
       }else{
         this.loading.dismiss();
         alert(obj['msg']);
       }
      
          });




        });


		this.routerData = this.activatedRoute.params['value'];
    if (this.routerData && this.routerData.type) {
      let productImages = this.routerData.productImage.split(',');
      this.uploadImageArray = productImages;
      this.dataForm.patchValue({
        category: this.routerData.category,
			  type: this.routerData.type,
			  name: this.routerData.name,
        brand: this.routerData.brand,
        productImage: productImages,
			  Description: this.routerData.Description,
				priceRange: JSON.parse(this.routerData.priceRange),
				moq: this.routerData.moq,
        tradeTerms: this.routerData.tradeTerms,
        container: this.routerData.container
      });
    }
	}
	

  showWorkTask(){

  }


  onChangeM(event){


    var item = this.mesuList.find(item => item.id ===  event.detail.value);
    this.selectedMeasure = item.name;
    this.selectedMeasures = item.namep;
    if(item.id == 17 || item.id == 16 || item.id == 15){
     this.is_container = true;

    }else{
      this.is_container = false;
    }


    
    }

  sendEmail(id){
    var lang;
    if(this.language == null || !this.language == null || this.language == 'en'){
      lang = 'en';
    } else if(this.language == 'es'){
      lang = 'es';
    }

    this.http.get('https://pandalaglobal.com/restpgt/index.php/Pandala/emailreq/'+id+'/'+lang).subscribe(() => {
    });
  }


  imgPreview(event, i) {
    if (event.target.files === 0) {
      return;
    }
    var file = event.target.files[0];
    let mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    // console.log(this.imgList);
    if (!this.imgList[i]) {
      reader.onload = (_event) => {
        this.imgList.push({ url: reader.result, path: file });
        event.target.value = null;
      };
    }
    if (this.imgList[i]) {
      reader.onload = (_event) => {
        this.imgList[i].path = file;
        this.imgList[i].url = reader.result;
        event.target.value = null;
      };
    }
  }

  imgRemove(i) {
    this.imgList.splice(i, 1);
  }



submitForm() {
this.loading.present();



this.storage.get('request_img_1').then(val => {
  this.dataForm.value['img1'] = val;
   });

  this.storage.get('request_img_2').then(val2 => {
    this.dataForm.value['img2'] = val2;
   });

  this.storage.get('request_img_3').then(val3 => {
    this.dataForm.value['img3'] = val3;
   });

for (let index = 0; index < this.imgList.length; index++) {
  const element = this.imgList[index];
  if (index == 0) {
    this.dataForm.value['thumb'] = element.url;
  } else {
    this.dataForm.value['thumb' + index] = element.url;
  }
}


		if(!this.dataForm.valid) {
      if(this.language =='en'){
        this.presentToast('Please, complete all the required information before submit your request.');
      }else if(this.language=='cn'){
        this.presentToast('Please, complete all the required information before submit your request.');
      }else if(this.language=='es'){
        this.presentToast('Valida tu formulario. Se requiere más información para una óptima búsqueda.');
      }
 
         this.loading.dismiss();

    } else if(this.routerData && this.routerData.type){
			// update Request
      // this.updateRequest(this.dataForm.value,this.routerData.key);
      this.loading.dismiss();
    } else if (this.dayCount >= 5) {

      if(this.language =='en'){
        this.presentToast('Sorry, you have reached the maximun daily requests.');
      }else if(this.language=='cn'){
        this.presentToast('Sorry, you have reached the maximun daily requests.');
      }else if(this.language=='es'){
        this.presentToast('Lo sentimos, has alcanzado el máximo de solicitudes.');
      }

      
      this.loading.dismiss();

    } else {
      this.dayCount = 0;  
      this.storage.get('user').then(userResult => {

            
            this.dataForm.value.userId = userResult.id;
            this.dataForm.value.created = Date();
            this.dataForm.value.productImage = this.uploadImageArray;
            this.dataForm.value.requestId = this.reqid;
            this.dataForm.value.ref_no = this.reqid_show;
            if(this.type){
              this.dataForm.value.type_req = this.type;
            }else{
              this.dataForm.value.type_req = 1;
            }
           







               //console.log(this.dataForm.value); 


              this.requestProvider.sendRequest(this.dataForm.value, this.imgList).subscribe((data: any) => {
            
              //console.log(data);
              if(data['error'] == false ){



                this.loading.dismiss().then(() => {





                  this.imgList = [];


                  
                  this.sendEmail(data['id']);
  
                  if(this.router.url != '/tabs/sourcing'){ 
                    this.router.navigate(['/tabs/sourcing']);
                  }
                  this.loading.dismiss();
                  this.closeModal();
  
                  if(this.language =='en'){
                    this.presentToast('The Request has been successfully created!');
                  }else if(this.language=='cn'){
                    this.presentToast('The Request has been successfully created!');
                  }else if(this.language=='es'){
                    this.presentToast('Solicitud enviada con éxito!');
                  }
  
  
                
  
  
  
                  
                  this.storage.remove('request_img_1');
                  this.storage.remove('request_img_2');
                  this.storage.remove('request_img_3');
                  this.closeModal();
                
                });

              }else{

                if(this.language =='en'){
    
                }else if(this.language=='cn'){
                  this.presentToast('Something went wrong with your request. Please, contact us as soon as you can.');
                }else if(this.language=='es'){
                  this.presentToast('El envío de la solicitud ha fallado!');
                }
                this.loading.dismiss();
                this.closeModal();
         

              }
           
              });
          
          // }
        // });
      });
		}
  }
  



  async presentToast(mess:string) {
		const toast = await this.toastCtrl.create({
      message: '<b>'+mess+'</b>',
			duration: 4500,
      position: 'middle',
      color: 'light'
		});
    toast.present();
  }


  // public uploadImage($event,index) {
  //   let folderName = 'request';
  //   let file = $event.target['files'];

  //  this.loading.present();

  //   this.uploadProvider.upload(file[0],folderName).then(result => {
  //     if(result && result.length > 0) {
  //       this.uploadImageArray[index] = result;
  //       this.loading.dismiss();
  //     }      
  //   },error => {
  //     this.loading.dismiss();
  //     this.presentToast('Por favor intente otra vez');
  //   })
  // }

  public formatTime(date, fmt) {
    var o = {
      "M+": date.getMonth() + 1, 
      "d+": date.getDate(), 
      "h+": date.getHours(),
      "m+": date.getMinutes(), 
      "s+": date.getSeconds(), 
      "q+": Math.floor((date.getMonth() + 3) / 3), 
      "S": date.getMilliseconds()
    };
    if(/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for(var k in o)
      if(new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
	}


  public flete($event) {
		let value = $event.detail.value;
     this.freight = value;

	}

	
	public minChange($event) {
		let value = $event.detail.value;
		let temp = this.dataForm.value.priceRange;
    temp.lower = value;
		this.dataForm.patchValue({
			priceRange: temp
		})
	}

	public maxChange($event) {
		let value = $event.detail.value;
		let temp = this.dataForm.value.priceRange;
		temp.upper = value;
		this.dataForm.patchValue({
			priceRange: temp
		})	
	}


  /**
   * Find in the data whether there is a suitable product
   * Also consider adding multiple matching conditions, not just names, such as type, or categroy
   * @param {string} userId 
   * @param {string} requestId 
   * @memberof SendRequestPage
   */



  async presentToast2(msg) {
		const toast = await this.toastCtrl.create({
			message: msg,
			duration: 8000,
			position: 'middle',
			cssClass: 'toast-app'
		});
		toast.present();
  }

  async presentToast3(msg) {
		const toast = await this.toastCtrl.create({
			message: msg,
			duration: 5500,
			position: 'middle',
			cssClass: 'toast-app'
		});
		toast.present();
  }
  

  help1(msg) {

    this.presentToast2(msg);
  }

  help2(msg) {

    this.presentToast3(msg);
  }


  async  closeModal() {
    this.modalCtrl.dismiss();
  }



  async changeImage() {



		const image = await Camera.getPhoto({
			quality: 90,
			allowEditing: false,
			resultType: CameraResultType.Base64,
			source: CameraSource.Photos // Camera, Photos or Prompt!
		});

    // console.log(image)
	
		if (image) {
	this.loading.present();
	
			const result = await this.uploadService.uploadImageReq(image,this.user.id,this.counterImg,this.reqid);
	
	var headero;
  var messageo;
  var headerf;
  var messagef;

  if(this.language=='es'){
	
headero ='Error de subida';
messageo ='Hubo un problema cuando intentamos cargar tu imágen de refererencia. Por favor, intenta con otra imágen.';
headerf ='';
messagef ='';
  }else{
    headerf ='Upload failed';
    messagef ='There was a problem when we were uploading your image. Please, try again with a different one.';
    headero ='';
    messageo ='';
  }
  

			if (result == false) {
				const alert = await this.alertCtrl.create({
					header: headerf,
					message: messagef,
					buttons: ['OK']
				});

				await alert.present();
			}else{




        const alert = await this.alertCtrl.create({
					header: 'Upload failed',
					message: 'There was a problem uploading your avatar. Try again.',
					buttons: ['OK']
				});
        this.counterImg ++;

			}
	
	
			this.loading.dismiss();
		}
	}


}


