import { Component, OnInit, Input } from '@angular/core';
import { ModalController , AlertController, ToastController} from '@ionic/angular';
import { RestPandalaService } from '../../providers/rest-pandala.service';
import { ExternalService } from '../../providers/external.service';
import { LoadingService } from '../../loading.service';
import { UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
@Component({
  selector: 'app-invoice-data',
  templateUrl: './invoice-data.component.html',
  styleUrls: ['./invoice-data.component.scss'],
})
export class InvoiceDataComponent implements OnInit {
  @Input() language;
  @Input() uid;
  @Input() tax_name;
  @Input() tax_id;
  @Input() tax_address;
  @Input() tax_scope;
  @Input() tax_contact;
  ready:boolean = false;
ready_d:number =0;

	public invoiceForm;


  placeholder = {
		'name': {select:
			{ en: 'Must be a legally registered company name', es: 'Ingrese nombre legal de empresa', cn: '' },
		},
		  'taxid': {select:
			{ en: 'Type Tax Id of your company', es: 'Ingrese número de RUT / ID / ABN', cn: ''  },
		   } ,
		   'address': {select:
			{ en: 'Type your Company Address for Billing purpose', es: 'Ingrese dirección de facturación', cn: ''  },
		   } ,
		   'bscope': {select:
			{ en: 'Business scope', es: 'Ingrese el rubro o giro del negocio', cn: ''  },
		   } ,
		   'contact': {select:
			{ en: 'Type your contact information', es: 'Ingrese información de contacto', cn: ''  },
		   } ,
	
	}

    constructor( private storage: Storage, public loading: LoadingService, public alertCtrl:AlertController, public event: ExternalService, public modalController: ModalController, public alertController: AlertController, private _restPandala: RestPandalaService, public formBuilder: UntypedFormBuilder, public toastCtrl: ToastController, public navCtrl: NavController) {
      setTimeout( () => {
        this.ready=true;
   }, 2000);





     }


  
    ngOnInit() {

// this.loading.present();

//   var params = {lang:this.language, ucountry:this.ucountry};
//   this._restPandala.getBankDetails(params).subscribe(data => {
//      console.log(data)
//      this.bankDetails = data['fflist'][0].content;
//      this.beneficiario = data['fflist'][0].beneficiary;
//      this.banco = data['fflist'][0].bank;
//      this.direccion = data['fflist'][0].address;
//      this.cuenta = data['fflist'][0].account;
//      this.swift = data['fflist'][0].swift;
//    this.ready_d =1;
//    this.loading.dismiss();
  //  })

  if (this.tax_name){
    this.invoiceForm = this.formBuilder.group({
      // email: ['demo@gmail.com', Validators.compose([Validators.required, EmailValidator.isValid])],
      // password: ['123456', Validators.compose([Validators.minLength(6), Validators.required])]  
      name: [this.tax_name, Validators.compose([Validators.required])],
      taxid: [this.tax_id, Validators.compose([Validators.required])],
      address: [this.tax_address, Validators.compose([Validators.minLength(1), Validators.required])],
      scope: [this.tax_scope, Validators.compose([Validators.minLength(1), Validators.required])],
      contact:[this.tax_contact, Validators.compose([Validators.minLength(1), Validators.required])],
      });
      this.ready=true;
      this.ready_d=1;
  }else{
    this.invoiceForm = this.formBuilder.group({
      // email: ['demo@gmail.com', Validators.compose([Validators.required, EmailValidator.isValid])],
      // password: ['123456', Validators.compose([Validators.minLength(6), Validators.required])]  
      name: ['', Validators.compose([Validators.required])],
      taxid: ['', Validators.compose([Validators.required])],
      address: ['', Validators.compose([Validators.minLength(1), Validators.required])],
      scope: ['', Validators.compose([Validators.minLength(1), Validators.required])],
      contact:['', Validators.compose([Validators.minLength(1), Validators.required])],
      });

      this.ready=true;
      this.ready_d=1;
  }



    }
    
    validPurchase(id) {
      // Purchase confirm 
      //Do whatever you want to do
    }


    ionViewDidEnter() {

    }





    async presentToast(mess:string) {
      const toast = await this.toastCtrl.create({
        message: '<b>'+mess+'</b>',
        duration: 4500,
        position: 'middle',
        color: 'light'
      });
      toast.present();
    }


    submitForm(){
      this.loading.present();
  if(!this.invoiceForm.valid) {
    if(this.language =='en'){
      this.presentToast('Please, complete all the required information before you submit the information.');
    }else if(this.language=='cn'){
      this.presentToast('Please, complete all the required information before you submit the information.');
    }else if(this.language=='es'){
      this.presentToast('Completa todos los campos para actualizar los datos de facturación.');
    }


    this.loading.dismiss();
}else{





this._restPandala.updateInvoiceData({

    lang:this.language,
    uid: this.uid,
    tax_id: this.invoiceForm.value.taxid,
    tax_name: this.invoiceForm.value.name,
    tax_address: this.invoiceForm.value.address,
    tax_scope: this.invoiceForm.value.scope,
    tax_contact: this.invoiceForm.value.contact,

	}).subscribe(obj => {
	  console.log(obj);
	if(obj['code'] == 1){
  


	   
		this._restPandala.userIndex({
		  lang: this.language,
		  uid: this.uid, 
		  }).subscribe(obj2 => {
			// console.log(obj2);
		  if(obj2['code'] == 1){

				this.loading.dismiss();
			this.storage.set('user', obj2['fflist']).then(() => {
			this.event.updateUser();

      this.dismissModal();

      
			});
			
		   
		  }else{
		  
	this.presentAlertErr(obj['msg']);
			this.loading.dismiss();
		  }
		  });


  
	}else{
	
	 this.presentAlertErr(obj['msg']);
	  this.loading.dismiss();
	}
	});
  

}

  }

  dismissModal(){
    this.modalController.dismiss();
  }


  async presentAlertErr(err) {
		const alert = await this.alertCtrl.create({
			message: err,
			buttons: [{
				text: "Ok",
				role: 'cancel'
			}]
		});
		await alert.present();
	}


}