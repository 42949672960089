import { Injectable } from '@angular/core';
import { doc, docData, Firestore, setDoc } from '@angular/fire/firestore';
import { getDownloadURL, ref, Storage, uploadString } from '@angular/fire/storage';
import { Photo } from '@capacitor/camera';
import { RestPandalaService } from './rest-pandala.service';

@Injectable({
	providedIn: 'root'
})
export class AvatarService {
	constructor(private firestore: Firestore, private storage: Storage, public rest: RestPandalaService) {}

	getUserProfile(userid) {
 const uid = userid;
		const userDocRef = doc(this.firestore, `users/${uid}`);
		return docData(userDocRef, { idField: 'id' });
	}

	async uploadImage(cameraFile: Photo, userid) {
	
    const uid = userid;
		const path = `uploads/${uid}/profile.webp`;
		const storageRef = ref(this.storage, path);

		try {
			await uploadString(storageRef, cameraFile.base64String, 'base64');

			const imageUrl = await getDownloadURL(storageRef);
      const uid = userid;
			const userDocRef = doc(this.firestore, `request-img/${uid}/`);
      

			await setDoc(userDocRef, {
				imageUrl
			});
      //tencent handling
      console.log(imageUrl);
      const url = encodeURIComponent(imageUrl);
      const todo = 0;
      const id = null;
  

    //   await this.rest.updateFile({url:url, todo: todo, id: id, uid: uid})
    //   return true;

      this.rest.updateFile({url:url, todo: todo, id: id, uid: uid}).subscribe(data => {

    console.log(data);

    return true;
  
    });


    
		} catch (e) {
			return false;
		}
	}
}