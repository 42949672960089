import { Component, OnInit, Input } from '@angular/core';
import { ModalController , AlertController} from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationExtras } from '@angular/router';
import { NavController } from '@ionic/angular';
import { RequestComponent } from 'src/app/sourcing/request/request.component';


@Component({
  selector: 'app-tutorial-mark',
  templateUrl: './tutorial-mark.component.html',
  styleUrls: ['./tutorial-mark.component.scss'],
})
export class TutorialMarkComponent implements OnInit {
  @Input() uid;
  @Input() language;
  mode: any = null;


  texto: any ={
      "main_title": { es: "¿Compras de China y necesitas que transportemos tu carga hasta tu negocio?" , en:"Do you buy from China and need us to transport your cargo to your business?"},
      "main_sub": { es: " Selecciona el origen de la compra:" , en:"Select the origin of your purchase:"},
      "op1": { es: "Compra desde Pandala Global" , en:"Purchase from Pandala Global"},
      "or":{ es: "o" , en:"or"},
      "op2": { es: "Compra desde Proveedores externos" , en:"Purchase from external Suppliers"},
      "back": { es: "Volver al Inicio" , en:"Back to Home"},
      "main_1": { es: "Compra desde Proveedores o Plataformas externas" , en:"Purchases from external Suppliers"},
      "1_op1": { es: "Descargar Instrucciones" , en:"Download Instructions"},
      "1_op2": { es: "Descargar Archivo PPL" , en:"Download PPL file"},
      "main_2": { es: "Compra desde Pandala Global" , en:"Purchase from Pandala Global"},
      "main_2_sub": { es: "Selecciona una opción:" , en:"Select on of these options:"},
      "2_op1": { es: "Pedidos en Curso" , en:"My Orders"},
      "2_op2": { es: "Solicita Mercancía" , en:"Request goods"},

   
  }

  constructor(
  public modalController: ModalController, public alertController: AlertController,
    private route: ActivatedRoute,
    public router: Router,
    public navCtrl: NavController
    ) { 

  
    }

  ngOnInit() {


   // console.log('Language:', this.language); // Esto te ayudará a verificar si el valor está llegando al modal
    //console.log('UID:', this.uid);
  
    
  }

toExt(){
  this.mode = "externo";
}

toInt(){
  this.mode = "interno";
}

traderContact(){

  this.closeModal();
    this.router.navigateByUrl('trader-contact');
}

toUrl(n){



  var url_pl= "https://cms.pandala.com.cn/admin/download_ppl.php/?id="+this.uid;
  

  if(this.uid){

    var url_ins= "http://cms.pandala.com.cn/admin/instructions-cn.php?id="+this.uid;
if (n==0){

  if(this.language == 'es'){

  }else{

  }
  this.presentAlert();


}

else if (n==1){
  document.location.href = url_pl;

}

}else{
  this.navCtrl.navigateRoot('account');
this.closeModal();
  
}

}

async presentAlert() {
var subheader = '';
var  header = '';
var content = '';
var btn = '';
  if(this.language =='es'){

var  header = 'Instrucciones';
var subheader = 'Etiquetas y Instrucciones';
var content = 'Generaremos un archivo pdf para que compartas a tu proveedor. Selecciona descargar y espera hasta que el archivo sea correctamente generado.';
var btn = 'Descargar';
  }else{
    var header = 'Instructions';
    var subheader = 'Marks and Instructions';
    var content = 'We will generate a pdf file. You must share it with your suppliers. Select download and wait until this file is successfully generated.';
    var btn = 'Download';
  }
  var url_ins= "http://cms.pandala.com.cn/admin/instructions-cn.php?id="+this.uid;
  const alert = await this.alertController.create({
    header: header,
    subHeader: subheader,
    message: content,
    
   buttons: [

      {
        text: btn,
        handler: () => {
          this.goToUrl(url_ins);
        }
      }
    ]

  });

  await alert.present();
}


goToUrl(a): void {
  window.open(a, "_blank");
}

  closeModal(){

      this.modalController.dismiss();


  }


  toOrders(){
    this.closeModal();
    this.router.navigateByUrl('/tabs/myorder');
  }


  toReq(){
    this.closeModal();

  }


  async modalSourcing() {

    if(this.uid){

 
    this.modalController.dismiss();
    let modal;
      modal = await this.modalController.create({
        component: RequestComponent,
        componentProps: {},
      });
      await modal.present();



    modal.onDidDismiss().then((data) => {
      if (data !== null) {
        this.ngOnInit();
      }
    });

  }else{
    this.navCtrl.navigateRoot('account');
this.closeModal();
  }

}

}
