
import { APP_INITIALIZER, NgModule, Optional, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { isPlatformServer } from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RestPandalaService } from './providers/rest-pandala.service';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';

import { ReactiveFormsModule } from '@angular/forms';

import { IonicSelectableModule } from 'ionic-selectable';


import { PremiumComponent } from './components/premium/premium.component';

import { environment } from '../environments/environment';
import { RequestComponent } from './sourcing/request/request.component';
import { DetailsComponent } from './sourcing/details/details.component';
import { RequestInspectionComponent } from './inspection/request-inspection/request-inspection.component';
import { CommonModule } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MapService } from './providers/map.service';

import { BankDetailsComponent } from './components/bank-details/bank-details.component';
import { ComponentsModule } from './components/components.module';
import { MenuComponent } from './components/menu/menu.component';
import { PipeModule } from './pipes/pipe.module';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';

import { IonIntlTelInputModule } from 'ion-intl-tel-input';

import { AirTrackingComponent } from './components/air-tracking/air-tracking.component';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { provideStorage,getStorage } from '@angular/fire/storage';
import { AvatarService } from './providers/avatar.service';
import { UploadService } from './providers/upload.service';



@NgModule({
    declarations: [AppComponent, RequestComponent, RequestInspectionComponent, DetailsComponent, MenuComponent, BankDetailsComponent,],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        IonIntlTelInputModule,
        CommonModule,
        NgxIonicImageViewerModule,
        PipeModule,
        ComponentsModule,
        IonicSelectableModule,
        ReactiveFormsModule,
        IonicStorageModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFirestore(() => getFirestore()),
        provideStorage(() => getStorage())
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },     {
            provide: APP_INITIALIZER,
            useFactory: (platformId: object, response: any) => {
              return () => {
                // In the server.ts we added a custom response header with information about the device requesting the app
                if (isPlatformServer(platformId)) {
                  if (response && response !== null) {
                    // Get custom header from the response sent from the server.ts
                    const mobileDeviceHeader = response.get('mobile-device');
      
                    // Set Ionic config mode?
                  }
                }
              };
            },
            deps: [PLATFORM_ID, [new Optional(), RESPONSE]],
            multi: true
          },
        RestPandalaService,
        MapService,
        AvatarService,
        UploadService,
        CommonModule,
  
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}


