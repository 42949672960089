
import { Component, OnInit, Input } from '@angular/core';
import { ModalController , AlertController} from '@ionic/angular';
import { RestPandalaService } from '../../providers/rest-pandala.service';
import { ExternalService } from '../../providers/external.service';
import { LoadingService } from '../../loading.service';
import {Observable} from 'rxjs';

declare var paypal: any;

@Component({
  selector: 'app-premium',
  templateUrl: './premium.component.html',
  styleUrls: ['./premium.component.scss','./styles/getting-started.page.scss',
  './styles/getting-started.shell.scss',
  './styles/getting-started.responsive.scss'],
})
export class PremiumComponent implements OnInit {
  @Input() reqid; 
  @Input() language;
  @Input() type;
  @Input() uid;
  @Input() ucountry;
  ready:boolean = false;
  option: number = 0;
  typex:number = 1;
  trader_ready:boolean = false
  showPayOptions:boolean =false;
  bankSelected:number = 0;
  paypalSelected:number = 0;
  showPaypal:number = 0;
  saleOptions:any;
  ready_d:number=0;
  selectedvalueOpt:any;
  showPaymod:number = 1;
  showBankmod:number = 0;
  dataPurchase:any; 
  p_amount:number = 0.00;
  p_amount_total:number = 0.00;
  price;
  want_prepaid:boolean =false;
  u_prepaid_amount:number = 0.00;
  u_prepaid_to_use:number = 0.00;
total_pay:number = 0.00;

fullPrepaid:boolean = false;
  payFor:boolean = false;
  payStyle:boolean = false;
trader:any;


  paymentAmount: string = '3.33';
  currency: string = 'USD';
  currencyIcon: string = 'USD';
  didPaypalScriptLoad:boolean = false;
intervalId:any;

    constructor(public loading: LoadingService, public alertCtrl:AlertController, public event: ExternalService, public modalController: ModalController, public alertController: AlertController, private _restPandala: RestPandalaService) {
      setTimeout( () => {
        this.ready=true;


   }, 2000);

   console.log(this.trader);




  //  this.intervalId = setInterval(() => {
  //   const elementExists = !!document.getElementById('paypal-button')
  //   if (elementExists) {
  //     this.loadPaypalScript().then(() => {
  //       paypal.Button.render(this.paypalConfig, '#paypal-button')
  //       this.loading.dismiss();
  //     })
  //   }
  // }, 1000)




  // this.intervalId = setInterval(() => {
  //   const elementExists = !!document.getElementById('paypal-button')
  //   if (elementExists) {
  //     this.loadPaypalScript().then(() => {
  //       paypal.Button.render(this.paypalConfig, '#paypal-button')
  //       this.loading = false
  //     })
  //   }
  // }, 1000)

  //  this.payPal();
 


     }

    


    public loadPaypalScript():Observable<any>{
      return new Observable(() => {
        clearInterval(this.intervalId);
      });
  }

  public ngAfterViewChecked(): void {
    const elementExists: boolean = !!document.getElementById('paypal-button-container');
    if(elementExists && !this.didPaypalScriptLoad) {

      this.payPal();
        this.loading.dismiss();

    }
  }

payPal(){
  this.didPaypalScriptLoad=true;
  let _this = this;
  
  setTimeout(() => {
    // Render the PayPal button into #paypal-button-container
    <any>window['paypal'].Buttons({


      createOrder: function (data, actions) {
        // console.log(data);
        // console.log(actions);
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: _this.event.format2Decimal(_this.total_pay)
            },
            application_context: {
              shipping_preference: 'NO_SHIPPING'
           }
          }]
        });
      },


      // Finalize the transaction
       onApprove: function (data, actions) {
         return actions.order.capture()
          .then(function (details) {
            _this.loading.present();



            var jsonx = JSON.stringify(details);

            console.log(jsonx);




             var datax:any = {details: jsonx, id_req: _this.reqid, lang: _this.language, uid: _this.uid,  id_purchase: _this.dataPurchase.id, qty: _this.dataPurchase.qty, type: _this.type, price:_this.dataPurchase.price, s_fee: _this.dataPurchase.s_fee_fix , total_price: _this.total_pay, prepaid: _this.u_prepaid_to_use, prepaid_original: _this.u_prepaid_amount, ucountry: _this.ucountry , trader_id: _this.trader.id}
             
         console.log(datax);

         _this._restPandala.newSalebyPaypal(datax).subscribe(obj => {
          console.log(obj);
           if(obj['code'] == 1){
            //  console.log(obj['fflist']);
             _this.presentAlertErr2(obj['msg']);
             _this.loading.dismiss();
             _this.dismissModal();
           }else{
           
            _this.presentAlertErr2(obj['msg']);
            _this.bankSelected = 0;
           
           }
           });
          })
          .catch(err => {
       
            console.log(err);
            _this.loading.dismiss();
            _this.presentAlertErr2('Transaction Error!');
          })
      }
    }).render('#paypal-button-container');
  }, 600)

}

 onChange(event){

this.total_pay = 0;
this.want_prepaid=false;

  this.selectedvalueOpt = event.detail.value;
  var item = this.saleOptions.find(item => item.id ===  this.selectedvalueOpt);
  this.dataPurchase = item;
  this.total_pay = item.total_price;
  // this.paymentAmount = this.event.format2Decimal(item.total_price);
 if(this.type>0){
   this.showPayOptions=true;
 }
  
  }
  
    ngOnInit() {







this.loading.present();



this._restPandala.getTrader({lang:this.language,uid:this.uid}).subscribe(data => {

  this.trader = data['fflist'];
  // console.log(this.trader.id);
  this.trader_ready=true;
  if(this.trader.id){




  var params = {lang:this.language, type: this.type};
  this._restPandala.getPurchaseOptions(params).subscribe(data => {
    //  console.log(data)
   this.saleOptions = data['fflist'];


   if(this.type==3){
    // this.selectedvalueOpt = data['fflist'][1].id;
   

    this.dataPurchase = data['fflist'][0];
    this.ready_d =1;
    this.loading.dismiss();
    this.payStyle=true;
   }else{


    this.selectedvalueOpt = data['fflist'][1].id;
   
    this.paymentAmount = this.event.format2Decimal(data['fflist'][1].total_price);

    this.dataPurchase = data['fflist'][1];
    this.total_pay = data['fflist'][1].total_price;




    this._restPandala.checkBalancePrepaid({uid:this.uid,todo:1,lang:this.language}).subscribe(obj => {

    if(obj['code'] == 1){
     
      this.u_prepaid_amount= obj['fflist'].prepaid_amount;
  
      this.ready_d =1;
     this.loading.dismiss();
  
    }else{
    
     this.presentAlertErr2(obj['msg']);
  
      this.loading.dismiss();
      this.dismissModal();
    }
    });

    
    


    

   }


   });
  }else{

    if(this.language=='es'){
      alert("No tienes agente de negocios asociado a tu cuenta.");
    }else{
      alert("You do not have any trader associated with your account.");
    }
    this.loading.dismiss();
this.dismissModal();

  }
  });


    }
    
    validPurchase(id) {
      // Purchase confirm 
      //Do whatever you want to do
    }




    showPay(){
      this.showPaymod = 1; 
      this.showBankmod = 0;
    }


    showBank(){
      this.showBankmod = 1;
      this.showPaymod = 0;
    }

    async presentAlertConfirm() {

    if(this.language=='en'){


      const alert = await this.alertController.create({
        //mode:'ios',
        header: 'T/T Payment',
        message: 'Do you want to pay by international bank wire transfer?. Once you confirmed, a new purchase order will be placed with the supplied bank details. When you successfully made the transfer of this purchase, you must double confirm the payment by clicking <b><i>Money sent to PG</i></b>. ',
        buttons: [
        {
          text: 'Back',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.bankSelected = 0;
            if(this.type<3){
              this.payStyle = false;
            }
          }
        }, {
          text: 'Confirm',
          handler: () => {
            if(this.type==3){
              var data:any = {id_req: this.reqid, lang: this.language, uid: this.uid,  id_purchase: this.dataPurchase.id, qty: this.dataPurchase.qty, type:this.type, price:this.p_amount, s_fee: this.dataPurchase.s_fee_fix , total_price: this.p_amount_total,  prepaid: 0, ucountry: this.ucountry, trader_id: this.trader.id}
            }else{
              var data:any = {id_req: this.reqid, lang: this.language, uid: this.uid,  id_purchase: this.dataPurchase.id, qty: this.dataPurchase.qty, type:this.type, price:this.dataPurchase.price, s_fee: this.dataPurchase.s_fee_fix , total_price: this.total_pay, prepaid: this.u_prepaid_to_use, prepaid_original:this.u_prepaid_amount, ucountry: this.ucountry, trader_id: this.trader.id}
            }
            
          // console.log(data);
          this.newSalebyBank(data);
          }
        }
        ]
      });
      
      await alert.present();

    }else if(this.language=='es'){


      const alert = await this.alertController.create({
        //mode:'ios',
        header: 'T/T Transferencia Bancaria Internacional',
        message: 'Al confirmar el pago mediante transferencia bancaria internacional, una nueva orden de compra se generará junto con los datos bancarios de Pandala Global en China. Al realizar el pago y envío efectivo de la transferencia, deberás dar click o presionar el botón <b><i>Dinero enviado a PG</i></b>. ',
        buttons: [
        {
          text: 'Back',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.bankSelected = 0;
            if(this.type<3){
              this.payStyle = false;
            }
     
          }
        }, {
          text: 'Confirm',
          handler: () => {
            if(this.type==3){
              var data:any = {id_req: this.reqid, lang: this.language, uid: this.uid,  id_purchase: this.dataPurchase.id, qty: this.dataPurchase.qty, type:this.type, price:this.p_amount, s_fee: this.dataPurchase.s_fee_fix , total_price: this.p_amount_total, ucountry: this.ucountry, trader_id: this.trader.id}
            }else{
              var data:any = {id_req: this.reqid, lang: this.language, uid: this.uid,  id_purchase: this.dataPurchase.id, qty: this.dataPurchase.qty, type:this.type, price:this.dataPurchase.price, s_fee: this.dataPurchase.s_fee_fix , total_price: this.total_pay, prepaid: this.u_prepaid_to_use, prepaid_original:this.u_prepaid_amount, ucountry: this.ucountry, trader_id: this.trader.id}
            }
           
            console.log(data);
            this.newSalebyBank(data);
          // this.managePay(0);
          }
        }
        ]
      });
      
      await alert.present();

    } else if (this.language=='cn'){


    }

  }


  prepaidChange(event){
    this.p_amount = event.detail.value;
    this.p_amount_total =  (Number(event.detail.value) + Number(this.dataPurchase.s_fee_fix) + Number(event.detail.value * this.dataPurchase.s_fee/100));
    console.log(this.p_amount);

  }

  wantPrepaidChange(event){
  
    this.loading.present();
if(this.want_prepaid == true){


  this._restPandala.checkBalancePrepaid({uid:this.uid,todo:1,lang:this.language}).subscribe(obj => {
    console.log(obj);
  if(obj['code'] == 1){
   
    this.u_prepaid_amount= obj['fflist'].prepaid_amount;

    if(this.total_pay > this.u_prepaid_amount){
      this.u_prepaid_to_use = this.u_prepaid_amount;
      this.payStyle=false;
  
   }else{
   
   this.u_prepaid_to_use = this.total_pay;
   this.fullPrepaid = true; 
   this.payStyle=true;

   }
   this.loading.dismiss();
   this.total_pay = this.total_pay-this.u_prepaid_to_use;

  }else{
  
   this.presentAlertErr2(obj['msg']);

    this.loading.dismiss();
    this.dismissModal();
  }
  });




      this.p_amount_total
    }else{
    

      if(this.payStyle==true){
        this.payStyle=false;
        
      }else{
  
      }
   this.fullPrepaid=false;
  //  this.payPal();
      this._restPandala.checkBalancePrepaid({uid:this.uid,todo:1,lang:this.language}).subscribe(obj => {
      if(obj['code'] == 1){
        this.u_prepaid_to_use = 0;
        this.u_prepaid_amount= obj['fflist'].prepaid_amount;
        this.total_pay = this.dataPurchase.total_price;
        this.loading.dismiss();
    
      }else{
      
       this.presentAlertErr2(obj['msg']);
    
        this.loading.dismiss();
        this.dismissModal();
      }
      });


      


    }


  }

newSalebyBank(params){
  this.loading.present();
  this._restPandala.newSaleByBank(params).subscribe(obj => {
      // console.log(obj);
    if(obj['code'] == 1){
      console.log(obj['fflist']);
      this.presentAlertErr2(obj['msg']);
      this.loading.dismiss();
      this.dismissModal();
    }else{
    
     this.presentAlertErr2(obj['msg']);
     this.bankSelected = 0;
      this.loading.dismiss();
    }
    });
}


newSaleFullBalace(params){
  this.loading.present();
  this._restPandala.newSaleByFullBalance(params).subscribe(obj => {
      console.log(obj);
    if(obj['code'] == 1){
      console.log(obj['fflist']);
      this.presentAlertErr2(obj['msg']);
      this.loading.dismiss();
      this.dismissModal();
    }else{
    
     this.presentAlertErr2(obj['msg']);
     this.loading.dismiss();
      this.dismissModal();
    }
    });


}


newSalebyPaypal(params){

}



async presentAlertErr2(msg) {
  const alert = await this.alertCtrl.create({
    message: msg,
    buttons: [{
      text: "Ok",
      role: 'cancel'
    }]
  });
  await alert.present();
}

  managePay(type){

  }


  onChangeBank(event){
    if(event.detail.value == 1){
      this.bankSelected = 1;
      this.payStyle=true;
      this.presentAlertConfirm();
    }else{
      this.bankSelected = 0;
    }
  }

  onPaypal(event){
    if(event.detail.value == 1){
      this.paypalSelected = 1;
      this.showPaypal = 1;
    }else{
      this.showPaypal = 0;
    }
  }

    dismissModal(){
      this.modalController.dismiss();
    }


    async presentAlertConfirmPrepaid() {

      if(this.language=='en'){
  
  
        const alert = await this.alertController.create({
          //mode:'ios',
          header: 'Full Prepaid Purchase',
          message: 'Do you want to complete this transaction using your Prepaid Balance?. Once you confirmed, the new purchase order will be immediately placed and your new Prepaid balance will be <b>'+this.event.format2Decimal(this.u_prepaid_amount-this.u_prepaid_to_use)+'<b> USD.' ,
          buttons: [
          {
            text: 'Back',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              
            }
          }, {
            text: 'Confirm',
            handler: () => {

              
       
              var data:any = {id_req: this.reqid, lang: this.language, uid: this.uid,  id_purchase: this.dataPurchase.id, qty: this.dataPurchase.qty, type:this.type, price:this.dataPurchase.price, s_fee: this.dataPurchase.s_fee_fix , total_price: this.total_pay, prepaid: this.u_prepaid_to_use, prepaid_original: this.u_prepaid_amount, ucountry: this.ucountry, trader_id: this.trader.id}
              
              
            this.newSaleFullBalace(data);
            }
          }
          ]
        });
        
        await alert.present();
  
      }else if(this.language=='es'){
  
  
        const alert = await this.alertController.create({
          //mode:'ios',
          header: 'Pago con Prepago',
          message: '¿Estás seguro que quieres finalizar la transacción utilizando tu monto de Prepago?. Una vez confirmes, inmediatamente se generará la orden de compra y tu nuevo saldo de solicitudes estará activo para su uso. Por otro lado, tu nuevo monto de Prepago será <b>'+this.event.format2Decimal(this.u_prepaid_amount-this.u_prepaid_to_use)+'<b> USD.' ,
          buttons: [
          {
            text: 'Volver',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              
            }
          }, {
            text: 'Confirmar',
            handler: () => {
       
                var data:any = {id_req: this.reqid, lang: this.language, uid: this.uid,  id_purchase: this.dataPurchase.id, qty: this.dataPurchase.qty, type:this.type, price:this.dataPurchase.price, s_fee: this.dataPurchase.s_fee_fix , total_price: this.total_pay, prepaid: this.u_prepaid_to_use, prepaid_original: this.u_prepaid_amount, ucountry: this.ucountry, trader_id: this.trader.id}
              
              
            this.newSaleFullBalace(data);
            }
          }
          ]
        });
        
        await alert.present();
  
      } else if (this.language=='cn'){
  
  
      }
  
    }

  }