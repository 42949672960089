import { Component, OnInit, HostListener } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ExternalService } from '../../providers/external.service';
import {Subscription} from 'rxjs';
import { Router, ActivatedRoute} from '@angular/router';
import { RestPandalaService } from '../../providers/rest-pandala.service';
import { LoadingService } from 'src/app/loading.service';
import { LangPopoverComponent } from '../lang-popover/lang-popover.component';
import { PopoverController, ModalController, NavController } from '@ionic/angular';
import { MenuComponent } from '../menu/menu.component';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

languageChangedSubscription: Subscription;
userChangedSubscription: Subscription;
cartChangedSubscription: Subscription;
fullname:any;
language:any;
screen:any;
user:any;
uid:any;
screenHeight: number;
screenWidth: number;
list_fob:any;
list_exw:any;
list_cart:any;
ustatus:any;
ready:boolean = false;

  constructor(public navCtrl: NavController, public loading: LoadingService, private storage: Storage, public event: ExternalService, private rest: RestPandalaService , public route: Router ,
	public popoverController: PopoverController,  public router: ActivatedRoute,  public modalCtrl: ModalController
	) {

    this.getScreenSize();


   }
gotoForm(){

	if (this.route.url === '/tabs/home') {
		this.scrollToForm(); // Si ya estamos en la página, solo hace el scroll
	  } else {
		this.route.navigate(['/tabs/home'], { queryParams: { form: 'contact' } }).then(() => {
		  this.scrollToForm(); // Cuando termina la navegación, hace el scroll
		});
	  }
}

  ngOnInit() {



    this.languageChangedSubscription = this.event.languageChanged$.subscribe(()=>{

      this.storage.get('language').then((val) => {
        this.language= val;

		this.storage.get('id').then((uid) => {
			if(uid){
				this.uid = uid;


				this.rest.userIndex({
					lang: this.language,
					uid: this.uid, 
					}).subscribe(obj2 => {
					//   console.log(obj2);
					if(obj2['code'] == 1){
		  
					//   this.loading.dismiss();
					this.user =  obj2['fflist'];
					  this.fullname = obj2['fflist'].fullname.replace(/ .*/,'');
					  this.ustatus = obj2['fflist'].status;
					  this.storage.set('user', obj2['fflist']).then(() => {

					  this.event.updateUser();
					  this.ready=true;
					//   this.navCtrl.navigateRoot('');
					  });
					  
					  this.router.queryParams.subscribe(params => {
						if (params['form'] === 'contact') {
						  this.scrollToForm();
						}
					  });
					  
					}else{
					this.user=null;
			 alert(obj2['msg']);
			 this.router.queryParams.subscribe(params => {
				if (params['form'] === 'contact') {
				  this.scrollToForm();
				}
			  });
					//   this.loading.dismiss();
					}
					});
			}else{
this.user= null;
this.ready=true;
this.router.queryParams.subscribe(params => {
	if (params['form'] === 'contact') {
	  this.scrollToForm();
	}
  });
			}
			


		});






       })
	})
	







	this.userChangedSubscription = this.event.userChanged$.subscribe(()=>{

		
		this.storage.get('user').then((val) => {
		  if(val){
			this.fullname = val.fullname.replace(/ .*/,'');
		  }

		 })
	  })




	  this.cartChangedSubscription = this.event.cartChanged$.subscribe(()=>{

			this.storage.get('exw_list').then((val2) => {
				if(!val2 || val2 == null ){
					this.list_exw = new Array();
				}else{
					this.list_exw = val2;
				}
				this.list_cart = this.list_exw;
		});
	

	
	  })

  }
  
  scrollToForm() {
    // Aquí puedes implementar el código para desplazarte al formulario de contacto
    const formElement = document.getElementById('contact-form');
    if (formElement) {
      formElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  
  async openModalMenu(){
	  this.loading.present();
    let modal;
      modal = await this.modalCtrl.create({
        component: MenuComponent,
		cssClass: "modal-fullscreen",
        componentProps: {language:this.language},
      });
      await modal.present();
	  this.loading.dismiss();


    modal.onDidDismiss().then((data) => {
      if (data !== null) {
        this.ngOnInit();
      }
    });

  }

	@HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
		  this.screenWidth = window.innerWidth;

          if(this.screenWidth<300) {
			this.screen=0;
			
		   }else if (this.screenWidth>=300  && this.screenWidth<425){
            this.screen=1;
          }else if (this.screenWidth>=425  && this.screenWidth<=800){
            this.screen=2;
		  }else if (this.screenWidth>800 || this.screenWidth == null || !this.screenWidth){
            this.screen=3;
          }
	}


  toEnglish(){
		//   this.events.publish('language: change', null);
			   this.storage.set('language', 'en').then(() => {
			 this.event.updateLanguage('en');
			 //this.menuCtrl.enable(false);
			 //this.navCtrl.navigateRoot('/splash');
	   
	   
	   
	   });
	      
			 
			 }
			 
			 toChinese(){
		   //  this.events.publish('language: change', 'cn');
			   this.storage.set('language', 'cn').then(() => {
			 this.event.updateLanguage('cn');
			 });
		   }
			 
			 
			 toSpanish(){
		   //  this.events.publish('language: change', 'sp');
			   this.storage.set('language', 'es').then(() => {
			 this.event.updateLanguage('es');
			 });
		   }

		   routeTo(a){
			   this.loading.present();
		      this.navCtrl.navigateRoot(a);
			  this.loading.dismiss();
		   }



		   async presentPopover(ev: any) {
			// console.log(ev);
			const popover = await this.popoverController.create({
			  component: LangPopoverComponent,
			  componentProps: {
				"ref": 'no',
				"product": 'no'
			  },
			//   cssClass: '',
			  event: ev,
			  translucent: false
			});
			await popover.present();
		
			const { role } = await popover.onDidDismiss();
			// this.ngOnInit(),role;
		  }

}
