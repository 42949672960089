import { Component, OnInit ,Input} from '@angular/core';
import {ModalController } from '@ionic/angular';
import {Subscription} from 'rxjs';
import { ExternalService } from '../../providers/external.service';
import { Router, ActivatedRoute} from '@angular/router';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
@Input() language;
languageChangedSubscription: Subscription;

  constructor( private event: ExternalService,private storage: Storage,public modalCtrl: ModalController, public route: Router ,public router: ActivatedRoute) { }

  ngOnInit() {

    this.languageChangedSubscription = this.event.languageChanged$.subscribe(()=>{
      this.storage.get('language').then((val) => {
        this.language= val;
       })
    })

  }


  ngOnDestroy(): void {
    if (this.languageChangedSubscription) {
      this.languageChangedSubscription.unsubscribe();
    }
  }

  dismissModal(){
this.modalCtrl.dismiss();
  }

  routeTo(a){
    this.route.navigateByUrl(a);
    this.dismissModal();
}



toEnglish(){
  //   this.events.publish('language: change', null);
     this.storage.set('language', 'en').then(() => {
       this.event.updateLanguage('en');
       //this.menuCtrl.enable(false);
       //this.navCtrl.navigateRoot('/splash');
 
 
 
 });
 
 
 
     
     }
     
     toChinese(){
     //  this.events.publish('language: change', 'cn');
     this.storage.set('language', 'cn').then(() => {
       this.event.updateLanguage('cn');
     });
   }
     
     
     toSpanish(){
     //  this.events.publish('language: change', 'sp');
     this.storage.set('language', 'es').then(() => {
       this.event.updateLanguage('es');
     });
   }


}
