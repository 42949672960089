import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { from } from 'rxjs';
import { EmailValidator } from '../validators/email';
import { DateTime } from 'luxon';
import { format, getUnixTime } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

@Injectable({
  providedIn: 'root'
})
export class RestPandalaService {

  setting: any = null;
  uploadProgress: any;
  task: any;
  ref: any;


  
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  constructor(public http: HttpClient) { 

  }


  
  getQuery(query:string) {
    const url =`https://pandalaglobal.com/restpgt/index.php/Pandala/${query}`;

    return this.http.get(url);
  }


  private convertDataToQueryString(payload) {
    let data = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
    return data;
  }


  getAds(params){
    let url_api = 'https://cms.pandala.com.cn/api_front/get_ads.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params)) 
  }
  

  emailVerification(params){
    let url_api = 'https://cms.pandala.com.cn/api_front/verification_register.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params), {
      headers: new HttpHeaders().set('Content-Type', 'text/plain'),
      responseType: 'text' as 'json'
   })
  }

updatePassword(params){
    let url_api = 'https://cms.pandala.com.cn/api_front/user_change_password.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }


  passVerification(params){
    let url_api = 'https://cms.pandala.com.cn/api_front/verification_password.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params), {
      headers: new HttpHeaders().set('Content-Type', 'text/plain'),
      responseType: 'text' as 'json'
   })
  }

  putAddress(id: any , company: string, fullname: string, address: string, phone: string ){


    let url=`https://pandalaglobal.com/restpgt/index.php/login/profile/`;
  
    let body = {
      id: id,
      company: company,
      fullname: fullname,
      address: address,
      phone: phone
    };
  
    return this.http.put(url,body).pipe(map(data=>data));
  
  
  }


  getNewRequestData(params){
    let url_api = 'https://cms.pandala.com.cn/api_front/get_new_request_data.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  getCheckoutData(params){
    let url_api = 'https://cms.pandala.com.cn/api_front/get_checkout_data.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  newUserByEmail(params){
    let url_api = 'https://cms.pandala.com.cn/api_front/user_register.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  updateInvoiceData(params){
    let url_api = 'https://cms.pandala.com.cn/api_front/user_invoice_data.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  myTraderCode(params){
    let url_api = 'https://cms.pandala.com.cn/api_front/trader_code.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  getTrader(params){
    let url_api = 'https://cms.pandala.com.cn/api_front/get_trader.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  checkBalancePrepaid(params){
    let url_api = 'https://cms.pandala.com.cn/api_front/prepaid_balance_check.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }
  
  newSaleByBank(params){
    let url_api = 'https://cms.pandala.com.cn/api_front/purchase_bank.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  newSaleByFullBalance(params){
    let url_api = 'https://cms.pandala.com.cn/api_front/purchase_full_prepaid.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  newSalebyPaypal(params){
    let url_api = 'https://cms.pandala.com.cn/api_front/purchase_paypal.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  loginByEmail(params){
    let url_api = 'https://cms.pandala.com.cn/api_front/user_login.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }
  
  userIndex(params){
    let url_api = 'https://cms.pandala.com.cn/api_front/user_index.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  getFreightFee(params){
    let url_api = 'https://cms.pandala.com.cn/api_front/freight_fee.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  getCountry(params){
    let url_api =  'https://cms.pandala.com.cn/api_front/get_countries.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  getCountriesNew(params){
    let url_api =  'https://cms.pandala.com.cn/api_front/get_world_country.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  getProvince(params){
    let url_api =  'https://cms.pandala.com.cn/api_front/get_province.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }
  getProvinceNew(params){
    let url_api =  'https://cms.pandala.com.cn/api_front/get_world_state.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }
  getCityNew(params){
    let url_api =  'https://cms.pandala.com.cn/api_front/get_world_city.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  getCity(params){
    let url_api =  'https://cms.pandala.com.cn/api_front/get_cities.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  getStorage(params){
    let url_api =  'https://cms.pandala.com.cn/api_front/get_storages.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  getDeliveryStorage(params){
    let url_api =  'https://cms.pandala.com.cn/api_front/get_storages_delivery.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  getPortsCMS(params){
    let url_api =  'https://cms.pandala.com.cn/api_front/get_ports.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  getIncoTerms(params){
    let url_api =  'https://cms.pandala.com.cn/api_front/get_incoterms.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  getAirFreight(params){
    let url_api =  'https://cms.pandala.com.cn/api_front/get_airfreight.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  getAirport(params){
    let url_api =  'https://cms.pandala.com.cn/api_front/get_airport.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  getProductsReq(params){
    let url_api =  'https://cms.pandala.com.cn/api_front/get_product_request.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  getNewestProducts(params){
    let url_api =  'https://cms.pandala.com.cn/api_front/get_product_latest.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }

  getFavByIdPd(params){

    let url_api =  'https://cms.pandala.com.cn/api_front/get_product_favorite.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))

  }

  getTermsData(params){

    let url_api =  'https://cms.pandala.com.cn/api_front/get_terms_data.php';
    return this.http.get(url_api+'?'+this.convertDataToQueryString(params))

  }

  translateApi(params){
    let url_api = 'https://cms.pandala.com.cn/api_front/get_translation.php';
  return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
  }


  addReq(id,id_req,id_user){

    let url=`https://pandalaglobal.com/restpgt/index.php/pandala/request/`;
  
    let body = {
id:id,
id_req:id_req,
id_user:id_user
    };
  
    //console.log(body)
    return this.http.post(url,body).pipe(map(data=>data));
  }


  //request





  public createRequestId(): string {
    // Obtener la zona horaria del usuario
    const userTimezone = DateTime.local().zoneName;
    
    // Obtener el desplazamiento de la zona horaria en minutos
    const userTimezoneOffset = zonedTimeToUtc(new Date(), userTimezone).getTimezoneOffset();

    // Obtener la marca de tiempo actual en segundos
    const timestamp = getUnixTime(new Date());

    // Convertir la marca de tiempo a una fecha legible
    const formattedDate = DateTime.fromSeconds(timestamp).toFormat('yyyyMMdd_HHmmss');

    // Crear el ID único con un prefijo y formato mejorado
    const uniqueID = `REQ-${userTimezoneOffset}-${formattedDate}`;

    return uniqueID;
}
	

	public formatTime(date, fmt) {
    var o = {
      "M+": date.getMonth() + 1, 
      "d+": date.getDate(), 
      "h+": date.getHours(),
      "m+": date.getMinutes(), 
      "s+": date.getSeconds(), 
      "q+": Math.floor((date.getMonth() + 3) / 3), 
      "S": date.getMilliseconds()
    };
    if(/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for(var k in o)
      if(new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
  }

  // public sendRequest(val:Array<any>):Promise<any> {
  //   console.log(val);
  //   return this.afs.collection('request').add(val);
  // }



  sendFreightQ(details,products,files) {
    var formData = new FormData();
      let url = `https://pandalaglobal.com/restpgt/index.php/Pandala/postfwq`;
      // let body = {
      //   details: details
      // };
      formData.append('details', JSON.stringify(details));
      formData.append('products', JSON.stringify(products));
      for (let i = 0; i < files.length; i++) {
        if (files[i] != null) {
          const img = files[i].path;
          if (img != null) {
            formData.append('file[]', img, img['name']);
          }
        }
      }
      return this.http.post(url, formData).pipe(map(data => data));
    }

    sendIdentityV(details,files) {

      var formData = new FormData();
        let url = `https://pandalaglobal.com/restpgt/index.php/Pandala/identityv`;
        // let body = {
        //   details: details
        // };
        formData.append('details', JSON.stringify(details));
        for (let i = 0; i < files.length; i++) {
          if (files[i] != null) {
            const img = files[i].path;
            if (img != null) {
              formData.append('file[]', img, img['name']);
            }
          }
        }
        return this.http.post(url, formData).pipe(map(data => data));
      }

      sendFiletoChat(details,files) {

        var formData = new FormData();
          let url = `https://pandalaglobal.com/restpgt/index.php/Pandala/filetraderchat`;
          // let body = {
          //   details: details
          // };
          formData.append('details', JSON.stringify(details));
          for (let i = 0; i < files.length; i++) {
            if (files[i] != null) {
              const img = files[i].path;
              if (img != null) {
                formData.append('file[]', img, img['name']);
              }
            }
          }
          return this.http.post(url, formData).pipe(map(data => data));
        }


     

      sendBackupDocV(details,files) {

        var formData = new FormData();
          let url = `https://pandalaglobal.com/restpgt/index.php/Pandala/backupdocv`;
          // let body = {
          //   details: details
          // };
          formData.append('details', JSON.stringify(details));
          for (let i = 0; i < files.length; i++) {
            if (files[i] != null) {
              const img = files[i].path;
              if (img != null) {
                formData.append('file[]', img, img['name']);
              }
            }
          }
          return this.http.post(url, formData).pipe(map(data => data));
        }


  sendRequest(details, images) {
    var formData = new FormData();
      let url = `https://pandalaglobal.com/restpgt/index.php/Pandala/postreq/`;
      // let body = {
      //   details: details
      // };
      formData.append('details', JSON.stringify(details));
      for (let i = 0; i < images.length; i++) {
        if (images[i] != null) {
          const img = images[i].path;
          if (img != null) {
            formData.append('file[]', img, img['name']);
          }
        }
      }
      return this.http.post(url, formData).pipe(map(data => data));
    }



  
    getInspectionList(params){
      let url_api = 'https://cms.pandala.com.cn/api_front/get_inspection.php';
      return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
    }

    getSourcingList(params){
      let url_api = 'https://cms.pandala.com.cn/api_front/get_sourcing.php';
      return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
    }


    getSalesList(params){
      let url_api = 'https://cms.pandala.com.cn/api_front/get_sales.php';
      return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
    }

    getPurchaseOptions(params){
      let url_api = 'https://cms.pandala.com.cn/api_front/get_purchase_options.php';
      return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
    }

    updateInspection(params){
      let url_api = 'https://cms.pandala.com.cn/api_front/manage_inspection.php';
      return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
    }

    updateFile(params){
      let url_api = 'https://cms.pandala.com.cn/api_front/manage_uploads.php';
      return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
    }


    updateSourcing(params){
      let url_api = 'https://cms.pandala.com.cn/api_front/manage_sourcing.php';
      return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
    }
    getProductData(params){
      let url_api = 'https://cms.pandala.com.cn/api_front/get_product_data.php';
      return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
    }


    getWorkTaskData(params){
      let url_api = 'https://cms.pandala.com.cn/api_front/get_worktask_data.php';
      return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
    }

    dicApi(params){
      let url_api = 'https://cms.pandalaglobal.com/api_suppliers/api_dic2402.php';
      return this.http.get(url_api+'?'+this.convertDataToQueryString(params)) 
    }

    updatePurchase(params){
      let url_api = 'https://cms.pandala.com.cn/api_front/purchase_manage.php';
      return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
    }

    getBankDetails(params){
      let url_api = 'https://cms.pandala.com.cn/api_front/get_bank_details.php';
      return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
    }

    // updateInspection(id_req:string, status:any){

    //   let url=`https://pandalaglobal.com/restpgt/index.php/PandalaCMS/updateinsp/`;
  
  
    //   let body = {
    //     id_req: id_req,
    //     status: status,
    //   };
  
    //   console.log(body);
  
    //     return this.http.put(url,body).pipe(map(data=>data));
    // }


    sendInspectionB(details, files) {
    var formData = new FormData();
      let url = `https://pandalaglobal.com/restpgt/index.php/Pandala/postinspb/`;
      // let body = {
      //   details: details
      // };
      formData.append('details', JSON.stringify(details));
      for (let i = 0; i < files.length; i++) {
        if (files[i] != null) {
          const fil = files[i].path;
          if (fil != null) {
            formData.append('file[]', fil, fil['name']);
          }
        }
      }
      return this.http.post(url, formData).pipe(map(data => data));
    }




    sendProductInspect(details, images) {
      var formData = new FormData();
        let url = `https://pandalaglobal.com/restpgt/index.php/Pandala/postpinspect/`;
        // let body = {
        //   details: details
        // };
        formData.append('details', JSON.stringify(details));
        for (let i = 0; i < images.length; i++) {
          if (images[i] != null) {
            const img = images[i].path;
            if (img != null) {
              formData.append('file[]', img, img['name']);
            }
          }
        }
        return this.http.post(url, formData).pipe(map(data => data));
      }


    sendProductQ(details) {
      var formData = new FormData();
        let url = `https://pandalaglobal.com/restpgt/index.php/Pandala/postpquote/`;
        // let body = {
        //   details: details
        // };
        formData.append('details', JSON.stringify(details));

        return this.http.post(url, formData).pipe(map(data => data));
      }








getRequestbyUser(uid: any){

  return this.getQuery(`reqbyuid/${uid}`)
              .pipe(map(data=>data['reqbyuid']));

}


getFwQbyUser(uid: any){

  return this.getQuery(`fwqbyuid/${uid}`)
              .pipe(map(data=>data['fwqbyuid']));

}

  getProductByRequest(id_user:any, id_req:any){
    

    return this.getQuery(`productbyreq/${id_user}/${id_req}`)
                .pipe(map(data=>data['productbyreq']));

  }




  getProductsbyReq(id: any){

    return this.getQuery(`prodbyidreq/${id}`)
                .pipe(map(data=>data['prodbyidreq']));

  }

  putReqRating(rate:any, id_req:any){

    let url=`https://pandalaglobal.com/restpgt/index.php/pandala/reqrating/`;


    let body = {
      rate: rate,
      id_req: id_req,
    };

    //console.log(body);

      return this.http.put(url,body).pipe(map(data=>data));


  }


  putPreqRating(rate:any, id_req:any, id:any){

    let url=`https://pandalaglobal.com/restpgt/index.php/pandala/preqrating/`;


    let body = {
      rate: rate,
      id_req: id_req,
      id: id
    };

    //console.log(body);

      return this.http.put(url,body).pipe(map(data=>data));


  }




  // Products
  getProducts(){

    return this.getQuery(`products/`)
                .pipe(map(data=>data['products']));

  }


  getProductRequestbyUser(id_user:any){
    return this.getQuery(`productrequested/${id_user}`)
    .pipe(map(data=>data['productrequested']));

  }


  getProductnew(start: any, limit:any){
    

    return this.getQuery(`productnews/${start}/${limit}`)
                .pipe(map(data=>data['productnews']));

  }

  getProduct(start: any, limit:any){
    

    return this.getQuery(`productnew/${start}/${limit}`)
                .pipe(map(data=>data['productnew']));

  }

  getProductbyCompany(id_company: any, start: any, limit:any){
    

    return this.getQuery(`productbycompany/${id_company}/${start}/${limit}`)
                .pipe(map(data=>data['productbycompany']));

  }

  getProductBySlide(limit:any){
    

    return this.getQuery(`productslide/${limit}`)
                .pipe(map(data=>data['productslide']));

  }

  getProductByCreated(limit:any){
    

    return this.getQuery(`productcreated/${limit}`)
                .pipe(map(data=>data['productcreated']));

  }


  getProductById(id: any){

    return this.getQuery(`productbyid/${id}`)
                .pipe(map(data=>data['productbyid']));

  }

  getProductByCat(start: any, limit:any, id: any){
    

      return this.getQuery(`productbycat/${start}/${limit}/${id}`)
                  .pipe(map(data=>data['productbycat']));
  
    }
  

  getProductByRelated(id_company : any, id_product:any){

    return this.getQuery(`productbyrelation/${id_company}/${id_product}`)
                .pipe(map(data=>data['productbyrelation']));

  }

  getUserById(id: string) {

    return this.getQuery(`user/${id}`)
                .pipe(map(data=>data['user']));
  }

  //Categories

  getCategories() {

    return this.getQuery(`categories`)
                .pipe(map(data=>data['categories']));
  }



  getProductsbyCountry() {

    return this.getQuery(`categoriesbycountry`)
                .pipe(map(data=>data['categoriesbycountry']));
  }

  getChildCategories(id: string) {

    return this.getQuery(`child_categories/${id}`)
                .pipe(map(data=>data['child_categories']));
  }

  //Favorites

  putAddFavorites(id_product:string, id_user:number, id_fav:any){

    let url=`https://pandalaglobal.com/restpgt/index.php/pandala/add_favorites/`;


    let body = {
      id_product: id_product,
      id_user: id_user,
      id_fav: id_fav
    };

    let body2 = {
      id_product: id_product,
      id_user: id_user,
      id_fav: 0
    };
    //console.log(body);
   // console.log(body2);

    if(id_fav){

      return this.http.put(url,body).pipe(map(data=>data));
      
    }else{

      return this.http.put(url,body2).pipe(map(data=>data));
    }

  }


  getCatSpec() {


    return this.getQuery(`catgeneral/`)
      .pipe(map(data => data['catgeneral']));
  }


  getMesuSpec() {


    return this.getQuery(`meamoq/`)
      .pipe(map(data => data['meamoq']));
  }




  getFavByUserId(id: any) {

  return this.getQuery(`favbyid/${id}`)
              .pipe(map(data=>data['favbyid']));
}

//trackingcode
getTrackingCode(id: any) {

  return this.getQuery(`trackbycode/${id}`)
              .pipe(map(data=>data['trackbycode']));
}

//Currrencies

formatMoney(money){
  const formatter = Intl.NumberFormat();
  return formatter.format(money);
}

formatNumber(number){
  const formatter = Intl.NumberFormat();
  return formatter.format(number);
}


formatRound(number){
  const formatter = Math.ceil(number)
  return formatter;
}

format2Decimal(number){
  const formatter =  parseFloat(number).toFixed(2);
  return formatter;
}


formatDecimal(number){
 var  int_part = Math.trunc(number); 
  const formatter = Math.round(Number((number-int_part).toFixed(2))*100);
  if(formatter ==0){
    return ('00');
  }else{
     if(formatter.toString().length == 2){
      return (formatter);
     }else{
      return ('0'+formatter);
     }
   
   
  }

}

formatInt(number){
  const formatter = Math.trunc(number);
  if(formatter == 0){
    return (' 0 ');
  }else {
    return (formatter);
  }

}


//ORDERS OR QTS


  

  addOrders(order){

    let url=`https://pandalaglobal.com/restpgt/index.php/pandala/orderb2b/`;
  
    let body = {
order:order
    };
  
    //console.log(body)
    return this.http.post(url,body).pipe(map(data=>data));
  }


  getOrder(id_order: any){
    return this.getQuery(`order/${id_order}`)
    .pipe(map(data=>data['order']));
  }



  getOrdersSuccess(id_user: any, limit:any){
    return this.getQuery(`orderdone/${id_user}/${limit}`)
    .pipe(map(data=>data['orderdone']));

  }

  getOrdersPending(id_user: any, limit:any){
    return this.getQuery(`orderpending/${id_user}/${limit}`)
    .pipe(map(data=>data['orderpending']));
  }

  getProductsByOrder(id_order: any){
    return this.getQuery(`productbyorder/${id_order}`)
    .pipe(map(data=>data['productbyorder']));

  }


  

  getOrdersbyTrade(id_user: any, trade:any, country:any, limit:any) {
    return this.getQuery(`ordertrade/${id_user}/${trade}/${country}/${limit}`)
    .pipe(map(data=>data['ordertrade']));
  }






  putAddProductOrder(id_product:string, id_order:number, items:any){

    let url=`https://pandalaglobal.com/restpgt/index.php/pandala/productorder/`;


    let body = {
      id_product: id_product,
      id_order: id_order,
      items: items
    };

    //console.log(body);

      return this.http.post(url,body).pipe(map(data=>data));


  }

  deleteProductOrder(id_product:string, id_order:number){

    let url=`https://pandalaglobal.com/restpgt/index.php/pandala/deleteporder/`;


    let body = {
      id_product: id_product,
      id_order: id_order,
    };

    //console.log(body);

      return this.http.put(url,body).pipe(map(data=>data));


  }


  deleteProductInspection(id:string){

    let url=`https://pandalaglobal.com/restpgt/index.php/pandala/deletepinspection/`;


    let body = {
      id: id,
    };

    //console.log(body);

      return this.http.put(url,body).pipe(map(data=>data));


  }


  deleteOrder(id_order:number){

    let url=`https://pandalaglobal.com/restpgt/index.php/pandala/deleteorder/`;


    let body = {
      id_order: id_order,
    };

   // console.log(body);

      return this.http.put(url,body).pipe(map(data=>data));


  }



  confirmOrder(id_order:number){

    let url=`https://pandalaglobal.com/restpgt/index.php/pandala/confirmorder/`;


    let body = {
      id_order: id_order,
    };

    //console.log(body);

      return this.http.put(url,body).pipe(map(data=>data));


  }

//PORTS

getPorts() {

  return this.getQuery(`ports/`)
              .pipe(map(data=>data['ports']));

}

  //COuntries
  getCountries() {

    return this.getQuery(`countries/`)
                .pipe(map(data=>data['countries']));

  }

//COMPANY

getCompany(id: string) {

  return this.getQuery(`company/${id}`)
              .pipe(map(data=>data['company']));
}


//vontactform


getCatWebF(params){
  let url_api =  'https://cms.pandala.com.cn/api_front/get_catwebform.php';
  return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
}

getWebCatStatus(params){
  let url_api =  'https://cms.pandala.com.cn/api_front/get_web_cat_status.php';
  return this.http.get(url_api+'?'+this.convertDataToQueryString(params))
}



addContactForm(freeclass){

  let url=`https://pandalaglobal.com/restpgt/index.php/pandala/contactform/`;

  let body = {
    freeclass: freeclass
  };

  //console.log(body)
  return this.http.post(url,body).pipe(map(data=>data));
}


//chat trader
getMessages(start:number, jump:number, id_cliente:string, id_section:number){ 

  return this.getQuery(`message/${start}/${jump}/${id_cliente}/${id_section}`)
              .pipe(map(data=>data['message']));
}

getMessages_new(start:number, jump:number, id_cliente:string, id_section:number){ 

  return this.getQuery(`message_new/${start}/${jump}/${id_cliente}/${id_section}`)
              .pipe(map(data=>data['message_new']));
}


// QUOTATOR CHILE 



sendHeaderQ(details) {

  var formData = new FormData();
    let url = `https://pandalaglobal.com/restpgt/index.php/Pandala/postHquote/`;
    // let body = {
    //   details: details
    // };
    formData.append('details', JSON.stringify(details));

    return this.http.post(url, formData).pipe(map(data => data));
  }


  getIndicadores(){
    let url_api = 'https://mindicador.cl/api';
    return this.http.get(url_api) 
  }


  deletepQ(id:string){
    let url=`https://pandalaglobal.com/restpgt/index.php/pandala/deletepq/`;


    let body = {
      id: id,
    };

    //console.log(body);

      return this.http.put(url,body).pipe(map(data=>data));

  }







insertMessage(details) {
  console.log(details);
  var formData = new FormData();
  let url = 'https://pandalaglobal.com/restpgt/index.php/pandala/insert_message/';


  formData.append('details', JSON.stringify(details));



  return this.http.post(url, formData, { observe: 'response' }).pipe(
    map(response => {
      console.log(response.headers.get('Content-Type')); // Imprime el tipo de contenido en la consola
      return response;
    })
  );
  }

}


