import { Component, OnInit,Input} from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { RestPandalaService } from '../../providers/rest-pandala.service';
import { ExternalService } from '../../providers/external.service';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/loading.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {
  @Input() lang: string;
  @Input() terms: number;
data:any;
ready:boolean=false;
  constructor(public loading: LoadingService, public modalCtrl : ModalController, private _restPandala:RestPandalaService ) { }

  ngOnInit() {
    var params = {lang: this.lang, terms:this.terms};
this.loading.present();
    this._restPandala.getTermsData(params).subscribe(obj => {
  
      console.log(obj);
     if(obj['code']==1){
  
      this.data=obj['fflist'];
      console.log(this.data);
this.ready = true;
this.loading.dismiss();
  
     }else{
       this.loading.dismiss();
       alert(obj['msg']);
       this.loading.dismiss();
     }
    
        });


  }
dismissModal(){
  this.modalCtrl.dismiss();
}
}
