import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-air-tracking',
  templateUrl: './air-tracking.component.html',
  styleUrls: ['./air-tracking.component.scss'],
})
export class AirTrackingComponent implements OnInit {
  @Input() language; 
  dhl:any;
  ups:any;
  fedex:any;
  tnt:any;
  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {}

  dimissModal(){
    this.modalCtrl.dismiss();
  }

  dhlSearch(ev){
this.dhl = ev.detail.value;

  }


  upsSearch(ev){

    this.ups = ev.detail.value;
  }

  fedexSearch(ev){

    this.fedex = ev.detail.value;
  }

  tntSearch(ev){
    this.tnt = ev.detail.value;
  }



  dhlSearchGo(){

    var url = 'https://www.dhl.com/cn-en/home/tracking.html?tracking-id=';
    var extra= '&submit=1'
    var final_url = url + this.dhl + extra;
    window.open(final_url, "_blank");
    this.dimissModal();
  }


  upsSearchGo(){

    var url = ' https://wwwapps.ups.com/etracking/tracking.cgi?AWB=';
    var extra= '&commit=Track%21&requester=ST/'
    var final_url = url + this.ups + extra;
    window.open(final_url, "_blank");
    this.dimissModal();
    
  }

  fedexSearchGo(){

    var url = 'http://www.fedex.com/fedextrack/?AWB=';
    var extra= '&commit=Track%21'
    var final_url = url + this.fedex + extra;
    window.open(final_url, "_blank");
    this.dimissModal();
    
  }

  tntSearchGo(){
    var url = 'https://www.tnt.com/express/zh_cn/site/shipping-tools/tracking.html?AWB=';
    var extra= '&commit=Track%21'
    var final_url = url + this.tnt + extra;
    window.open(final_url, "_blank");
    this.dimissModal();
  }

}
